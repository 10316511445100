import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import {
  ShoppingOutlined,
  UndoOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
  DollarCircleFilled,
  StockOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { fetchDashboardData } from "../services/ApiServices/apiFunctions";
import BarChart from "../components/charts/BarChart";

function Home() {
  const [dashboardData, setDashboardData] = useState({
    salesData: 0,
    salesReturnData: 0,
    purchasesData: 0,
    purchaseReturnData: 0,
    expenseData: 0,
    zakatAmount: 0,
    inventoryAmount: 0,
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const data = await fetchDashboardData();
      if (data && data.responseCode === 0) {
        setDashboardData({
          salesData: data.responseObject.sumSales,
          salesReturnData: data.responseObject.sumSalesReturn,
          purchasesData: data.responseObject.sumPurchases,
          purchaseReturnData: data.responseObject.sumPurchaseReturn,
          expenseData: data.responseObject.sumExpenseData,
          zakatAmount: data.responseObject.zakatAmount,
          inventoryAmount: data.responseObject.inventoryAmount,
        });
      } else {
        setErrorMessage("Error: Invalid data format");
      }
    } catch (error) {
      setErrorMessage("Error fetching data. Please try again later.");
    } finally {
    }
  };

  const data = [
    { category: "Sales", value: dashboardData.salesData },
    { category: "Purchases", value: dashboardData.purchasesData },
    { category: "Sales Return", value: dashboardData.salesReturnData },
    { category: "Purchase Return", value: dashboardData.purchaseReturnData },
    { category: "Expense", value: dashboardData.expenseData },
    { category: "Zakat", value: dashboardData.zakatAmount },
  ];

  // Object mapping xField values to colors
  const colors = [
    "#1890ff",
    "#52c41a",
    "#faad14",
    "#eb2f96",
    "#722ed1",
    "#13c2c2",
  ];
  const colorMap: Record<string, string> = {};
  data.forEach((item, index) => {
    colorMap[item.category] = colors[index % colors.length];
  });

  return (
    <>
      <h2>Dashboard</h2>
      <Row gutter={[16, 16]}>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Card
            title="Sales (PKR)"
            bordered={true}
            extra={<ShoppingCartOutlined style={{ fontSize: 32 }} />}
            style={{
              background: "#ECECEC",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {dashboardData.salesData} PKR
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Card
            title="Sales Return (PKR)"
            bordered={true}
            extra={<UndoOutlined style={{ fontSize: 32 }} />}
            style={{
              background: "#ECECEC",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {dashboardData.salesReturnData} PKR
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Card
            title="Purchases (PKR)"
            bordered={true}
            extra={<ShoppingOutlined style={{ fontSize: 32 }} />}
            style={{
              background: "#ECECEC",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {dashboardData.purchasesData} PKR
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Card
            title="Purchase Return (PKR)"
            bordered={true}
            extra={<SyncOutlined style={{ fontSize: 32 }} />}
            style={{
              background: "#ECECEC",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {dashboardData.purchaseReturnData} PKR
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Card
            title="Expenses (PKR)"
            bordered={true}
            extra={<DollarOutlined style={{ fontSize: 32 }} />}
            style={{
              background: "#ECECEC",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {dashboardData.expenseData} PKR
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Card
            title="Inventory (PKR)"
            bordered={true}
            extra={<StockOutlined style={{ fontSize: 32 }} />}
            style={{
              background: "#ECECEC",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {dashboardData.inventoryAmount} PKR
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Card
            title="Zakat (PKR)"
            bordered={true}
            extra={<DollarCircleFilled style={{ fontSize: 32 }} />}
            style={{
              background: "#ECECEC",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {dashboardData.zakatAmount} PKR
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Chart">
            <BarChart
              data={data}
              xField="category"
              yField="value"
              colors={colorMap}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Home;
