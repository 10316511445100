import React, { useState, useEffect } from "react";
import { Table, Input, Select, Button, Alert, DatePicker, Card } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import {
  fetchExpenses,
  fetchExpenseCategories,
} from "../services/ApiServices/apiFunctions";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

interface DataItem {
  SerialNo: string;
  ExpenseCategory: string;
  Description: string | null;
  Date: string;
  Amount: string;
  category: {
    id: number;
    CategoryName: string;
  };
}

interface Category {
  id: number;
  CategoryName: string;
}

const ViewExpenses: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedExpenseCategoryId, setSelectedExpenseCategoryId] =
    useState<number>(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >(null);

  useEffect(() => {
    loadExpenseCategories();
    fetchTableData(
      pagination.current,
      pagination.pageSize,
      searchText,
      dateRange,
      selectedExpenseCategoryId
    );
  }, [pagination.current, pagination.pageSize, selectedExpenseCategoryId]);

  const fetchTableData = async (
    page: number,
    pageSize: number,
    searchText: string,
    dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    expenseCategoryId: number
  ) => {
    setLoading(true);
    setError(null);
    try {
      const fromDate = dateRange
        ? dateRange[0]?.format("YYYY-MM-DD")
        : undefined;
      const toDate = dateRange ? dateRange[1]?.format("YYYY-MM-DD") : undefined;

      // Use the new function to fetch sales invoices
      const response = await fetchExpenses(
        page,
        pageSize,
        searchText,
        fromDate,
        toDate,
        selectedExpenseCategoryId
      );
      setData(response.responseObject.expenses.data);
      setGrandTotal(response.responseObject.totalAmount);
      setPagination({
        ...pagination,
        total: response.responseObject.expenses.total,
      });
    } catch (error) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const loadExpenseCategories = async () => {
    // Update method name
    setLoading(true);
    try {
      const data = await fetchExpenseCategories(); // Update the API function names
      if (Array.isArray(data.responseObject)) {
        setCategories(data.responseObject);
        setError(null);
      } else {
        setError("Error: Invalid data format");
      }
    } catch (error) {
      setError("Error fetching expense categories. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleDateRangeChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    setDateRange(dates);
    setPagination({ ...pagination, current: 1 });
  };

  const columns = [
    {
      title: "Serial No",
      dataIndex: "id", // Use "id" as the unique identifier
      key: "id",
    },
    {
      title: "Expense Category",
      dataIndex: "category",
      key: "category",
      render: (category: any) => category?.CategoryName,
    },
    {
      title: "Description",
      dataIndex: "description", // Use "description" instead of "Description"
      key: "description",
    },
    {
      title: "Date",
      dataIndex: "date", // Use "date" instead of "Date"
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount", // Use "amount" instead of "Amount"
      key: "amount",
      render: (text: string) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
  ];

  return (
    <Card title="View Expenses">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <Select
            showSearch
            placeholder="Select an expense category"
            onChange={(value) => setSelectedExpenseCategoryId(value)}
          >
            {categories.map((category) => (
              <Select.Option
                key={`category_${category.id}`}
                value={category.id}
              >
                {category.CategoryName}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <RangePicker
            onChange={handleDateRangeChange}
            style={{ width: "100%" }}
            placeholder={["From Date", "To Date"]}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Button
            type="primary"
            onClick={() =>
              fetchTableData(
                pagination.current,
                pagination.pageSize,
                searchText,
                dateRange,
                selectedExpenseCategoryId
              )
            }
            icon={<FilterOutlined />}
          >
            Filter
          </Button>
        </div>
      </div>

      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey="id" // Use "id" as the unique identifier
        size="small"
        scroll={{ x: true }}
        bordered
        summary={(tableData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  <strong>Grand Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  <strong>{grandTotal}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Card>
  );
};

export default ViewExpenses;
