import React, { useState } from 'react';
import { DatePicker, Button, Table, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { fetchIncomeReport } from '../services/ApiServices/apiFunctions';

interface ReportItem {
  Category: string;
  Amount: number;
}

const columns = [
  {
    title: 'Category',
    dataIndex: 'Category',
    key: 'Category',
  },
  {
    title: 'Amount',
    dataIndex: 'Amount',
    key: 'Amount',
    render: (text: number) => (typeof text === 'number' ? `${text.toFixed(2)}` : text),
  },
];

function IncomeReport() {
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [reportData, setReportData] = useState<ReportItem[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchIncomeReportData = async () => {
    try {
      setLoading(true);
      if (startDate && endDate) {
        const response = await fetchIncomeReport(
          dayjs(startDate).format("YYYY-MM-DD"),
          dayjs(endDate).format("YYYY-MM-DD")
        );
        setReportData(response.responseObject);
      }
    } catch (error) {
      console.error('Error fetching income report:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateReport = () => {
    fetchIncomeReportData();
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Income Report</h1>

      <Row gutter={[16, 16]} align="middle">
        <Col xs={24} md={12} lg={8} xl={6}>
          <DatePicker
            onChange={(date: dayjs.Dayjs | null) => setStartDate(date)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <DatePicker
            onChange={(date: dayjs.Dayjs | null) => setEndDate(date)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Button
            type="primary"
            onClick={handleGenerateReport}
            style={{ width: '100%' }}
            loading={loading}
          >
            Generate Report
          </Button>
        </Col>
      </Row>

      <div style={{ marginTop: '20px' }}>
        {reportData.length > 0 && (
          <>
            <h2>Income Report Data</h2>
            <Table dataSource={reportData} columns={columns} pagination={false} />
          </>
        )}
      </div>
    </div>
  );
}

export default IncomeReport;
