// authInterceptor.ts
import { AxiosInstance } from 'axios';

// Function to add an authentication token to the headers
const addAuthTokenToHeaders = (axiosInstance: AxiosInstance) => {

  axiosInstance.interceptors.request.use(
    (config) => {
      // Add custom headers based on localStorage data
      const authToken = localStorage.getItem('token');
      const userId = localStorage.getItem('UserName');
      const identifier = localStorage.getItem('identifier');

      if (authToken) {
        config.headers['Auth_token'] = authToken;
      }
      
      if (userId) {
        config.headers['userID'] = userId;
      }

      if (identifier) {
        config.headers['Identifier'] = identifier;
      }

      config.headers['Content-Type'] = 'application/json';
      // Add the Authorization header with your token
      config.headers['Authorization'] = `Bearer ${authToken}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default addAuthTokenToHeaders;
