import logo from "./assets/images/logo.png";
export const APP_NAME = "Alwan Collection";
export const API_BASE_URL = "https://alwancollection.com/app/public/api";
//export const API_BASE_URL = "http://127.0.0.1:8000/api";
export const Logo = logo;
export const AuthHeaders = {
  Auth_token: localStorage.getItem("token"),
  userID: localStorage.getItem("username"),
  Identifier: localStorage.getItem("identifier"),
};
