// loadingInterceptor.ts
import { AxiosInstance } from 'axios';

let requestsCount = 0;

const addLoadingIndicatorInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    requestsCount++;
    // Show loading indicator
    // You can trigger your loading component here
    // For example, set a state variable in React to show the loading component
    // This code assumes you're using a state management library like React
    // In a React component, you can use useState to manage loading state

    // Example in React:
    // setLoading(true);

    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      requestsCount--;
      if (requestsCount === 0) {
        // Hide loading indicator
        // You can hide your loading component here
        // Example in React:
        // setLoading(false);
      }
      return response;
    },
    (error) => {
      requestsCount--;
      if (requestsCount === 0) {
        // Hide loading indicator on error
        // You can hide your loading component here
        // Example in React:
        // setLoading(false);
      }
      // Handle errors
      return Promise.reject(error);
    }
  );
};

export default addLoadingIndicatorInterceptor;
