import React, { useState, useEffect } from "react";
import { Card, Form, Select, Button, Input, message } from "antd";
import { CopyOutlined, KeyOutlined } from "@ant-design/icons";
import Utilities from "../services/Utilities";
import {
  fetchApprovedUsers,
  generatePassResetToken,
} from "../services/ApiServices/apiFunctions";

interface User {
  ID: number;
  UserName: string;
  // Add other properties if needed
}

function GenerateAdminToken() {
  const [form] = Form.useForm();
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [token, setToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await fetchApprovedUsers();
        setUsers(response.responseObject);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
    fetchUsers();
  }, []);

  async function generateToken(values: any) {
    setLoading(true);
    const selectedUser = users?.find(
      (user) => user.UserName === values.UserName
    );

    if (selectedUser) {
      try {
        const response = await generatePassResetToken(values);

        if (response.responseCode === 0) {
          setToken(response.responseObject.token);
          message.success("successfully received the Token");
        } else if (response.responseCode === 15) {
          setErrorMessage(response.responseObject);
        } else {
          setErrorMessage(response.responseDescription);
        }
      } catch (error) {
        console.error("Error generating token:", error);
      } finally {
        setLoading(false);
        setErrorMessage("");
      }
    }
  }

  const handleCopyToken = () => {
    Utilities.copyToClipboard(token);
    message.success("Token copied successfully");
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
  };

  return (
    <Card title="Token Generator">
      <Form
        {...formItemLayout}
        size="middle"
        form={form}
        onFinish={generateToken}
        layout="vertical"
        initialValues={{
          UserName: "",
          identifier: "",
        }}
      >
        <Form.Item
          label="Select User"
          name="UserName"
          rules={[
            {
              required: true,
              message: "Please select a user",
            },
          ]}
        >
          {users ? (
            <Select showSearch filterOption={false}>
              {users.map((user) => (
                <Select.Option key={user.ID} value={user.UserName}>
                  {user.UserName}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <p>Loading users...</p>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={<KeyOutlined />}
          >
            Generate Token
          </Button>
        </Form.Item>
        {token && (
          <Form.Item label="Generated Token">
            <Input
              value={token}
              readOnly
              addonAfter={
                <Button icon={<CopyOutlined />} onClick={handleCopyToken}>
                  Copy
                </Button>
              }
            />
          </Form.Item>
        )}
        <Form.Item>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </Form.Item>
      </Form>
    </Card>
  );
}

export default GenerateAdminToken;
