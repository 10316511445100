import React, { useState, useEffect } from "react";
import { Table, Input, Button, Alert, Card } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { fetchCurrentStock } from "../services/ApiServices/apiFunctions";

interface DataItem {
  ProductID: number;
  ProductName: string;
  Unit: string;
  Qty: number;
  PurchaseRate: number;
  Amount: number;
}

const CurrentStock: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchTableData(pagination.current, pagination.pageSize, searchText);
  }, [pagination.current, pagination.pageSize, searchText]);

  const fetchTableData = async (
    page: number,
    pageSize: number,
    searchText: string
  ) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchCurrentStock(page, pageSize, searchText);
      setGrandTotal(response.responseObject.totalSum);
      setData(response.responseObject.currentStock.data);
      setPagination({
        ...pagination,
        total: response.responseObject.currentStock.total,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
  };

  const columns = [
    {
      title: "Product ID",
      dataIndex: "ProductID",
      key: "ProductID",
    },
    {
      title: "Product Name",
      dataIndex: "ProductName",
      key: "ProductName",
    },
    {
      title: "Unit",
      dataIndex: "Unit",
      key: "Unit",
    },
    {
      title: "Qty",
      dataIndex: "Qty",
      key: "Qty",
      render: (text: string) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: "Rate",
      dataIndex: "PurchaseRate",
      key: "PurchaseRate",
      render: (text: string) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (text: string) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
  ];

  return (
    <Card title="Current Stock">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Button
            type="primary"
            onClick={() =>
              fetchTableData(
                pagination.current,
                pagination.pageSize,
                searchText
              )
            }
            icon={<FilterOutlined />}
          >
            Filter
          </Button>
        </div>
      </div>

      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey="id"
        size="small"
        scroll={{ x: true }}
        bordered
        summary={(tableData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  <strong>Grand Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  <strong>{grandTotal}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Card>
  );
};
export default CurrentStock;
