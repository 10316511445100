import React, { useEffect } from "react";
import { Form, Input, Card } from "antd";
import {
  ManOutlined,
  NumberOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Item } = Form;
const formItemLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 8 },
  },
};

function UserProfile() {
  const [form] = Form.useForm();

  useEffect(() => {
    // Retrieve values from local storage
    const storedUsername = localStorage.getItem("UserName");
    const storedName = localStorage.getItem("FullName");
    const storedCnic = localStorage.getItem("CNIC");
    const storedContact = localStorage.getItem("ContactNo");
    const storedRole = localStorage.getItem("Role");

    // Set the retrieved values as initial form values
    form.setFieldsValue({
      username: storedUsername,
      name: storedName,
      cnic: storedCnic,
      contact: storedContact,
      role: storedRole,
    });
  }, [form]);

  return (
    <>
      <Card title="User Profile">
        <Form {...formItemLayout} form={form} size="middle">
          <Item name="username" label="User Name">
            <Input
              prefix={<UserOutlined />}
              placeholder="User Name"
              style={{ pointerEvents: "none" }}
            />
          </Item>
          <Item name="name" label="Name">
            <Input
              prefix={<ManOutlined />}
              placeholder="Name"
              style={{ pointerEvents: "none" }}
            />
          </Item>
          <Item name="cnic" label="CNIC">
            <Input
              prefix={<NumberOutlined />}
              placeholder="CNIC"
              style={{ pointerEvents: "none" }}
            />
          </Item>
          <Item name="contact" label="Contact No">
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Contact No"
              style={{ pointerEvents: "none" }}
            />
          </Item>
          <Item name="role" label="Role">
            <Input
              prefix={<UserOutlined />}
              placeholder="Role"
              style={{ pointerEvents: "none" }}
            />
          </Item>
        </Form>
      </Card>
    </>
  );
}

export default UserProfile;
