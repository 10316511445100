import axios, { AxiosResponse } from 'axios';
import addAuthTokenInterceptor from './authInterceptor';
import addErrorHandlingInterceptor from './errorInterceptor';
import addLoadingIndicatorInterceptor from './loadingInterceptor';
import { API_BASE_URL } from '../../Config';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Replace with your API base URL
});

// Add the authentication interceptor
addAuthTokenInterceptor(axiosInstance);

// Add the loading indicator interceptor
addLoadingIndicatorInterceptor(axiosInstance);

//Add Error Handling
addErrorHandlingInterceptor(axiosInstance);

export const fetchDashboardData = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`dashbaord`);
    return response.data; // Return data on success
  } catch (error) {
    // Handle API error and return an appropriate response
    return { error: 'Error fetching dashbaord data' };
  }
};

export const fetchRegisteredUsers = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`getRegisteredUsers`);
    return response.data; // Return data on success
  } catch (error) {
    // Handle API error and return an appropriate response
    return { error: 'Error fetching users data' };
  }
};

export const fetchRolesAndBranches = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`getRolesAndBranches`);
    return response.data; // Return data on success
  } catch (error) {
    // Handle API error and return an appropriate response
    return { error: 'Error fetching Branches and Roles data' };
  }
};

export const fetchApprovedUsers = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`getApprovedUsers`);
    return response.data; // Return data on success
  } catch (error) {
    // Handle API error and return an appropriate response
    return { error: 'Error fetching Approved Users data' };
  }
};

export const approveUser = async (requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`approveUser`, requestBody);
    return response.data; // Return data on success
  } catch (error) {
    // Handle API error and return an appropriate response
    return { error: 'Error creating post' };
  }
};

export const generatePassResetToken = async (requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`generatePassResetToken`, requestBody);
    return response.data; // Return data on success
  } catch (error) {
    // Handle API error and return an appropriate response
    return { error: 'Error generating token' };
  }
};

export const resetPassword = async (requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.put(`resetPassword`, requestBody);
    return response.data; // Return data on success
  } catch (error) {
    // Handle API error and return an appropriate response
    return { error: 'Error in resetting password' };
  }
};
export const changePassword = async (requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.put(`changePassword`, requestBody);
    return response.data; // Return data on success
  } catch (error) {
    // Handle API error and return an appropriate response
    return { error: 'Error in changing password' };
  }
};

// Fetch all product categories
export const fetchCategories = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('product-categories');
    return response.data;
  } catch (error) {
    return { error: 'Error fetching product categories' };
  }
};

// Create a new product category
export const createCategory = async (requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post('product-categories', requestBody);
    return response.data;
  } catch (error) {
    return { error: 'Error creating product category' };
  }
};

// Add expesnses
export const createExpense = async (requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post('expense', requestBody);
    return response.data;
  } catch (error) {
    return { error: 'Error adding expenses' };
  }
};

// Add Zakat Payment
export const zakatPayment = async (requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post('zakat-pay', requestBody);
    return response.data;
  } catch (error) {
    return { error: 'Error paying zakat' };
  }
};

// Fetch a specific product category by ID
export const fetchCategory = async (id: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`product-categories/${id}`);
    return response.data;
  } catch (error) {
    return { error: 'Error fetching product category' };
  }
};

// Update an existing product category by ID
export const updateCategory = async (id: number, requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.put(`product-categories/${id}`, requestBody);
    return response.data;
  } catch (error) {
    return { error: 'Error updating product category' };
  }
};

// Delete a product category by ID
export const deleteCategory = async (id: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`product-categories/${id}`);
    return response.data;
  } catch (error) {
    return { error: 'Error deleting product category' };
  }
};

// Function to fetch all products
export const fetchProducts = async () => {
  try {
    const response = await axiosInstance.get('products');
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

// Fetch all product categories
export const fetchExpenseCategories = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('expense-categories');
    return response.data;
  } catch (error) {
    return { error: 'Error fetching expense categories' };
  }
};

// Create a new product category
export const createExpenseCategory = async (requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post('expense-categories', requestBody);
    return response.data;
  } catch (error) {
    return { error: 'Error creating expense category' };
  }
};

// Fetch a specific product category by ID
export const fetchExpenseCategory = async (id: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`expense-categories/${id}`);
    return response.data;
  } catch (error) {
    return { error: 'Error fetching expense category' };
  }
};

// Update an existing product category by ID
export const updateExpenseCategory = async (id: number, requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.put(`expense-categories/${id}`, requestBody);
    return response.data;
  } catch (error) {
    return { error: 'Error updating expense category' };
  }
};

// Delete a product category by ID
export const deleteExpenseCategory = async (id: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`expense-categories/${id}`);
    return response.data;
  } catch (error) {
    return { error: 'Error deleting expense category' };
  }
};

// Function to fetch all products
export const loadInvoiceProducts = async () => {
  try {
    const response = await axiosInstance.get('load-invoice-products');
    return response.data;
  } catch (error) {
    return { error: 'Error fetching products for invoice' };
  }
};

// Function to create a new product
export const createProduct = async (productData: any) => {
  try {
    const response = await axiosInstance.post('/products', productData);
    return response.data;
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};

// Function to fetch a single product by ID
export const fetchProductById = async (productId: number) => {
  try {
    const response = await axiosInstance.get(`/products/${productId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching product by ID:', error);
    throw error;
  }
};

// Function to update a product by ID
export const updateProduct = async (productId: number, productData: any) => {
  try {
    const response = await axiosInstance.put(`/products/${productId}`, productData);
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

// Function to delete a product by ID
export const deleteProduct = async (productId: number) => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`/products/${productId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting product:', error);
    throw error;
  }
};
// Function to create a new product
export const generateInvoice = async (invoiceData: any) => {
  try {
    const response = await axiosInstance.post('/invoice', invoiceData);
    return response.data;
  } catch (error) {
    console.error('Error in generating  invoice:', error);
    throw error;
  }
};

export const fetchInvoicesReport = async (
  page: number,
  pageSize: number,
  searchText: string,
  fromDate?: string,
  toDate?: string,
  InvoiceType?: string,
) => {
  try {
    const response = await axiosInstance.get('view-invoices', {
      params: {
        page,
        pageSize,
        searchText,
        fromDate,
        toDate,
        InvoiceType,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching invoices:', error);
    throw error;
  }
};

export const fetchInvoiceDetailsReport = async (
  page: number,
  pageSize: number,
  selectedProductID: number,
  fromDate?: string,
  toDate?: string,
  InvoiceType?: string,
) => {
  try {
    const response = await axiosInstance.get('view-invoice-details', {
      params: {
        page,
        pageSize,
        selectedProductID,
        fromDate,
        toDate,
        InvoiceType,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching invoices details:', error);
    throw error;
  }
};

export const fetchCurrentStock = async (
  page: number,
  pageSize: number,
  searchText: string,
) => {
  try {
    const response = await axiosInstance.get('current-stock', {
      params: {
        page,
        pageSize,
        searchText,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Current Stock', error);
    throw error;
  }
};

export const fetchExpenses = async (
  page: number,
  pageSize: number,
  searchText: string,
  fromDate?: string,
  toDate?: string,
  expenseCategoryId?: number,
) => {
  try {
    const response = await axiosInstance.get('expense', {
      params: {
        page,
        pageSize,
        searchText,
        fromDate,
        toDate,
        expenseCategoryId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching expense data:', error);
    throw error;
  }
};

export const fetchInvoice = async (
  InvoiceNo: string,
  InvoiceType: String) => {
  try {
    const response = await axiosInstance.get(`get-invoice`, {
      params: {
        InvoiceNo,
        InvoiceType,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Invoice Details:', error);
    throw error;
  }
};

export const fetchIncomeReport = async (
  startDate: string,
  endDate: String) => {
  try {
    const response = await axiosInstance.get(`income-report`, {
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Income Report:', error);
    throw error;
  }
};

export const fetchAccountLedger = async (
  page: number,
  pageSize: number,
  fromDate?: string,
  toDate?: string,
  accountID?: number,) => {
  try {
    const response = await axiosInstance.get(`account-ledger`, {
      params: {
        page,
        pageSize,
        fromDate,
        toDate,
        accountID,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchContacts = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('contacts');
    return response.data;
  } catch (error) {
    return { error: 'Error fetching contacts' };
  }
};

export const createContact = async (contactData: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post('contacts', contactData);
    return response.data;
  } catch (error) {
    return { error: 'Error creating contact' };
  }
};

export const fetchContactById = async (contactId: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`contacts/${contactId}`);
    return response.data;
  } catch (error) {
    return { error: 'Error fetching contact by ID' };
  }
};

export const updateContact = async (contactId: number, contactData: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.put(`contacts/${contactId}`, contactData);
    return response.data;
  } catch (error) {
    return { error: 'Error updating contact' };
  }
};

export const deleteContact = async (contactId: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`contacts/${contactId}`);
    return response.data;
  } catch (error) {
    return { error: 'Error deleting contact' };
  }
};

export const createVoucher = async (postData: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post('vouchers', postData);
    return response.data;
  } catch (error) {
    return { error: 'Error creating voucher' };
  }
};

// Fetch all accounts
export const fetchAccounts = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('accounts');
    return response.data.responseObject; // Return data on success
  } catch (error) {
    return { error: 'Error fetching accounts' };
  }
};

// Create a new account
export const createAccount = async (accountData: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post('accounts', accountData);
    return response.data; // Return data on success
  } catch (error) {
    return { error: 'Error creating account' };
  }
};

// Fetch a specific account by ID
export const fetchAccountById = async (accountId: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`accounts/${accountId}`);
    return response.data; // Return data on success
  } catch (error) {
    return { error: 'Error fetching account by ID' };
  }
};

// Update an existing account by ID
export const updateAccount = async (accountId: number, accountData: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.put(`accounts/${accountId}`, accountData);
    return response.data; // Return data on success
  } catch (error) {
    return { error: 'Error updating account' };
  }
};

// Delete an account by ID
export const deleteAccount = async (accountId: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`accounts/${accountId}`);
    return response.data; // Return data on success
  } catch (error) {
    return { error: 'Error deleting account' };
  }
};