import React, { useState } from "react";
import { Select, Spin } from "antd";

const { Option } = Select;

interface SearchableSelectProps<T> {
  options: T[];
  placeholder: string;
  onChange: (value: any) => void;
  loading?: boolean;
  idKey: keyof T;
  nameKey: keyof T;
}

const SearchableSelect = <T extends Record<string, any>>({
  options,
  placeholder,
  onChange,
  loading = false,
  idKey,
  nameKey,
}: SearchableSelectProps<T>) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <Select
      style={{ width: "100%" }}
      showSearch
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (String(option?.children) ?? "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      onChange={handleSelectChange}
      loading={loading}
      value={selectedValue}
    >
      {loading && (
        <Option value={undefined} disabled>
          <Spin size="small" />
        </Option>
      )}
      {options.map((option: T) => (
        <Option
          key={`option_${option[idKey]}`}
          value={String(option[idKey])}
          label={String(option[nameKey])}
        >
          {option[nameKey]}
        </Option>
      ))}
    </Select>
  );
};

export default SearchableSelect;
