import React, { useEffect } from "react";
import { useNavigate, Route, Routes } from "react-router-dom";
import { Layout, theme } from "antd";
import HeaderContent from "../components/Layout/HeaderContent";
import Sidebar from "../components/Layout/SideBarContent";
import ChangePasswordForm from "./ChangePasswordForm";
import UserProfile from "./UserProfile";
import GenerateAdminToken from "./GenerateAdminToken";
import UsersList from "./UsersList";
import ProductCategory from "./ProductCategory";
import Products from "./Products";
import InvoiceDetails from "./InvoiceDetails";
import ExpenseCategory from "./ExpenseCategory";
import ViewInvoices from "./ViewInvoices";
import CurrentStock from "./CurrentStock";
import Home from "./Home";
import Expenses from "./Expenses";
import ViewExpenses from "./ViewExpenses";
import IncomeReport from "./IncomeReport";
import Contacts from "./Contacts";
import Voucher from "./Voucher";
import LedgerView from "./LedgerView";
import ProductReport from "./ProductReport";
import { APP_NAME } from "../Config";
import OpeningForm from "./Opening";

const { Content, Footer } = Layout;

function Dashboard() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();

  useEffect(() => {
    // Check if there is a token in localStorage
    const token = localStorage.getItem("token");
    if (!token) {
      // If there is a token, navigate to the dashboard
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout>
        <HeaderContent />
        <Content
          style={{
            flex: 1,
            padding: "24px 16px",
            background: colorBgContainer,
          }}
        >
          <Routes>
            <Route path="home" element={<Home />} />
            <Route
              path="change-password"
              element={
                <ChangePasswordForm
                  title="Change Password"
                  showOldPassword={true}
                  showToken={false}
                  usernameReadOnly={true}
                  showNote={false}
                />
              }
            />
            <Route path="user-profile" element={<UserProfile />} />
            <Route
              path="generate-admin-token"
              element={<GenerateAdminToken />}
            />
            <Route path="users-list" element={<UsersList />} />
            <Route path="product-category" element={<ProductCategory />} />
            <Route path="expense-category" element={<ExpenseCategory />} />
            <Route path="products" element={<Products />} />
            <Route
              path="purchase-invoice"
              element={<InvoiceDetails InvoiceType="PURCHASE INVOICE" />}
            />
            <Route
              path="purchase-return"
              element={<InvoiceDetails InvoiceType="PURCHASE RETURN" />}
            />
            <Route
              path="sale-invoice"
              element={<InvoiceDetails InvoiceType="SALE INVOICE" />}
            />
            <Route
              path="sale-return"
              element={<InvoiceDetails InvoiceType="SALE RETURN" />}
            />
            <Route path="view-invoices" element={<ViewInvoices />} />
            <Route path="product-report" element={<ProductReport />} />
            <Route path="current-stock" element={<CurrentStock />} />
            <Route path="expenses/create" element={<Expenses />} />
            <Route path="expenses" element={<ViewExpenses />} />
            <Route path="income-report" element={<IncomeReport />} />
            <Route path="account-ledger" element={<LedgerView />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="opening-form" element={<OpeningForm />} />
            <Route
              path="payment-voucher"
              element={<Voucher voucherType={1} />}
            />
            <Route
              path="receipt-voucher"
              element={<Voucher voucherType={2} />}
            />
          </Routes>
        </Content>

        <Footer style={{ textAlign: "center" }}>
          {APP_NAME} Inventory 1.0.0 ©2024 Developed by Anas Ali Qureshi
        </Footer>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
