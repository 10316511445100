import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

interface ResultPageProps {
  statusCode?: string;
  title?: string;
  subtitle?: string;
  homeLink?: string;
}
function ResultPage({
  statusCode = "403", // Default to "403" if not provided
  title = "Permission Denied",
  subtitle = "Sorry, you don't have permission to access this page.",
  homeLink = "/dashboard",
}: ResultPageProps) {
  return (
    <Result
      status={statusCode as any} // Convert to 'any' to allow using strings
      title={title}
      subTitle={subtitle}
      extra={
        <Button type="primary">
          <Link to={homeLink}>Go Home</Link>
        </Button>
      }
    />
  );
}

export default ResultPage;
