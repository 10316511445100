import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Input,
  Button,
  Popconfirm,
  message,
  Card,
  Select,
  Modal,
  Tooltip,
  Tag,
  Col,
  Row,
} from "antd";
import {
  fetchProducts,
  createProduct,
  updateProduct,
  deleteProduct,
} from "../services/ApiServices/apiFunctions";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

const { Column } = Table;
const { Option } = Select;
const { Search } = Input;

interface Product {
  ID: number;
  BrandName: string;
  CategoryID: number;
  Unit: string;
  MinimumStock: number;
  SalePrice: number;
  Status: string;
}

interface Category {
  id: number;
  CategoryName: string;
}

function Products() {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  const [modalForm] = Form.useForm();

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    setLoading(true);
    try {
      const data = await fetchProducts();
      if (Array.isArray(data.responseObject.products)) {
        setProducts(data.responseObject.products);
        setCategories(data.responseObject.categories);
        setErrorMessage(null);
      } else {
        console.error("Invalid data format:", data);
        setErrorMessage("Error: Invalid data format");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setErrorMessage("Error fetching products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddProduct = async (values: any) => {
    values.BrandName = values.BrandName.toUpperCase();
    setAdding(true);
    try {
      const response = await createProduct(values);
      if (response.responseCode === 0) {
        message.success("Product added successfully");
        loadProducts();
        form.resetFields();
      } else if (response.responseObject.BrandName) {
        message.error(response.responseObject.BrandName);
      }
    } catch (error) {
      console.error("Error adding product:", error);
      message.error("Failed to add product");
    } finally {
      setAdding(false);
    }
  };

  const handleUpdateProduct = async (values: any) => {
    values.BrandName = values.BrandName.toUpperCase();
    if (selectedProduct) {
      setUpdating(true);
      try {
        const response = await updateProduct(selectedProduct.ID, values);
        if (response.responseCode === 0) {
          message.success("Product updated successfully");
          loadProducts();
          setUpdateModalVisible(false);
        } else {
          message.error("Failed to update product");
        }
      } catch (error) {
        console.error("Error updating product:", error);
        message.error("Failed to update product");
      } finally {
        setUpdating(false);
      }
    }
  };

  const handleDeleteProduct = async (ID: number) => {
    setDeleting(true);
    try {
      const res = await deleteProduct(ID);
      if (res.responseCode === 0) {
        message.success("Product deleted successfully");
        loadProducts();
      } else {
        message.error("Failed to delete product");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      message.error("Failed to delete product");
    } finally {
      setDeleting(false);
    }
  };
  //const [modalFormInitialValues, setModalFormInitialValues] =
  useState<Partial<Product> | null>(null);

  const showUpdateModal = (product: Product) => {
    setSelectedProduct(product);
    modalForm.setFieldsValue(product); // Set initial values for the modal form
    setUpdateModalVisible(true);
  };

  const hideUpdateModal = () => {
    setSelectedProduct(null);
    modalForm.resetFields(); // Clear the modal form
    setUpdateModalVisible(false);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const filteredProducts = products.filter(product =>
    Object.values(product).some(value =>
      value && value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <div>
      <Card title="Product Management">
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Form
          form={form}
          onFinish={handleAddProduct}
          layout="vertical"
          size="middle"
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="BrandName"
                label="Brand Name"
                rules={[
                  { required: true, message: "Brand name is required" },
                  {
                    max: 255,
                    message: "Brand name should be less than 255 characters",
                  },
                ]}
              >
                <Input placeholder="Enter brand name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="CategoryID"
                label="Category"
                rules={[{ required: true, message: "Category is required" }]}
              >
                <Select placeholder="Select a category">
                  {categories.map((category) => (
                    <Option key={`category_${category.id}`} value={category.id}>
                      {category.CategoryName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="Unit"
                label="Unit"
                rules={[{ required: true, message: "Unit is required" }]}
              >
                <Select placeholder="Select unit">
                  <Option value="Meter">Meter</Option>
                  <Option value="PIECE">PIECE</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="MinimumStock"
                label="Minimum Stock"
                rules={[
                  { required: true, message: "Minimum stock is required" },
                ]}
              >
                <Input type="number" placeholder="Enter minimum stock" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="SalePrice"
                label="Sale Price"
                rules={[{ required: true, message: "Sale price is required" }]}
              >
                <Input type="number" placeholder="Enter sale price" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={adding}
              disabled={adding || updating || deleting}
              icon={<FileAddOutlined />}
            >
              Add Product
            </Button>
          </Form.Item>
        </Form>
        <Search
          placeholder="Search products"
          onSearch={handleSearch}
          style={{ width: 200, float: 'right',marginBottom: 10}}
        />
        <Table
          dataSource={filteredProducts}
          rowKey="ID"
          loading={loading}
          pagination={{
            pageSize: 10,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} records`,
          }}
          size="small"
          scroll={{ x: true }}
          bordered
        >
          <Column title="Brand Name" dataIndex="BrandName" key="BrandName" />
          <Column
            title="Category"
            dataIndex="CategoryName"
            key="CategoryName"
          />
          <Column title="Unit" dataIndex="Unit" key="Unit" />
          <Column
            title="Minimum Stock"
            dataIndex="MinimumStock"
            key="MinimumStock"
          />
          <Column title="Sale Price" dataIndex="SalePrice" key="SalePrice" />
          <Column
            title="Status"
            dataIndex="Status"
            key="Status"
            render={(status) => (
              <Tag color={status === "active" ? "#87d068" : "#f50"}>
                {status.toUpperCase()}
              </Tag>
            )}
          />
          <Column
            title="Actions"
            key="actions"
            width="20%"
            render={(text, record: Product) => (
              <div>
                <Tooltip title="Edit">
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => showUpdateModal(record)}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you sure you want to delete this product?"
                    onConfirm={() => handleDeleteProduct(record.ID)}
                  >
                    <Button type="link" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          />
        </Table>
        <Modal
          title="Update Product"
          style={{ top: 20 }}
          open={isUpdateModalVisible}
          onOk={modalForm.submit}
          onCancel={hideUpdateModal}
          confirmLoading={updating}
        >
          <Form
            form={modalForm}
            onFinish={handleUpdateProduct}
            initialValues={{}}
            layout="vertical"
            size="small"
          >
            <Form.Item
              name="BrandName"
              label="Brand Name"
              rules={[
                { required: true, message: "Brand name is required" },
                {
                  max: 255,
                  message: "Brand name should be less than 255 characters",
                },
              ]}
            >
              <Input placeholder="Enter brand name" />
            </Form.Item>

            <Form.Item
              name="CategoryID"
              label="Category"
              rules={[{ required: true, message: "Category is required" }]}
            >
              <Select placeholder="Select a category">
                {categories.map((category) => (
                  <Option key={`category_${category.id}`} value={category.id}>
                    {category.CategoryName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="Unit"
              label="Unit"
              rules={[{ required: true, message: "Unit is required" }]}
            >
              <Select placeholder="Select unit">
                <Option value="Meter">Meter</Option>
                <Option value="PIECE">PIECE</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="MinimumStock"
              label="Minimum Stock"
              rules={[{ required: true, message: "Minimum stock is required" }]}
              style={{ textAlign: "right" }} // Add this style to align right
            >
              <Input type="number" placeholder="Enter minimum stock" />
            </Form.Item>
            <Form.Item
              name="SalePrice"
              label="Sale Price"
              rules={[{ required: true, message: "Sale price is required" }]}
              style={{ textAlign: "right" }} // Add this style to align right
            >
              <Input type="number" placeholder="Enter sale price" />
            </Form.Item>
            <Form.Item name="Status" label="Status">
              <Select placeholder="Select status">
                <Option value="active">Active</Option>
                <Option value="inactive">Inactive</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </div>
  );
}

export default Products;
