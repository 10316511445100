import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Card,
  Select,
  Col,
  Row,
  DatePicker,
} from "antd";

import {
  BankOutlined,
  DollarCircleOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

import {
  createExpense,
  fetchExpenseCategories,
} from "../services/ApiServices/apiFunctions";

const { Option } = Select;

interface Category {
  id: number;
  CategoryName: string;
}

function Expenses() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [paymentMethod, setPaymentMethod] = useState<string | null>("CASH");

  const handlePaymentMethodChange = (method: string) => {
    setPaymentMethod(method);
    form.setFieldsValue({ pay_mode: method }); // Set voucher_type
  };

  useEffect(() => {
    loadExpenseCategories();
    form.setFieldsValue({ date: selectedDate, pay_mode: paymentMethod });
  }, [form, selectedDate, paymentMethod]);

  const loadExpenseCategories = async () => {
    setLoading(true);
    try {
      const data = await fetchExpenseCategories();
      if (Array.isArray(data.responseObject)) {
        setCategories(data.responseObject);
        setErrorMessage(null);
      } else {
        setErrorMessage("Error: Invalid data format");
      }
    } catch (error) {
      console.error("Error fetching expense categories:", error);
      setErrorMessage(
        "Error fetching expense categories. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAddExpense = async (values: any) => {
    setAdding(true);
    try {
      values.date = dayjs(values.date).format("YYYY-MM-DD");
      const response = await createExpense(values);

      if (response.responseCode === 0) {
        message.success("Expense added successfully");
        form.resetFields();
        setSelectedDate(dayjs(values.date));
      } else if (response.responseObject.Category) {
        message.error(response.responseObject.Category);
      }
    } catch (error) {
      console.error("Error adding expense:", error);
      message.error("Failed to add expense");
    } finally {
      setAdding(false);
    }
  };

  return (
    <div>
      <Card title="Expense Management">
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Form
          form={form}
          onFinish={handleAddExpense}
          layout="vertical"
          size="middle"
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="expense_category"
                label="Expense Category"
                rules={[{ required: true, message: "Category is required" }]}
              >
                <Select
                  placeholder="Select an expense category"
                  loading={loading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (String(option?.children) ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {categories.map((category) => (
                    <Option
                      key={`category_${category.id}`}
                      value={category.id}
                      label={category.CategoryName}
                    >
                      {category.CategoryName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="pay_mode"
                label="Payment Method"
                rules={[
                  { required: true, message: "Payment method is required" },
                ]}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    type={paymentMethod === "CASH" ? "primary" : "default"}
                    onClick={() => handlePaymentMethodChange("CASH")}
                    style={{ flex: 1, marginRight: "8px" }}
                  >
                    <DollarCircleOutlined style={{ marginRight: "4px" }} />
                    Cash
                  </Button>
                  <Button
                    type={paymentMethod === "BANK" ? "primary" : "default"}
                    onClick={() => handlePaymentMethodChange("BANK")}
                    style={{ flex: 1 }}
                  >
                    <BankOutlined style={{ marginRight: "4px" }} />
                    Bank
                  </Button>
                </div>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[{ required: true, message: "Date is required" }]}
              >
                <DatePicker
                  defaultValue={
                    form.getFieldValue("date")
                      ? dayjs(form.getFieldValue("date"))
                      : selectedDate
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: "Description is required" }]}
              >
                <Input placeholder="Enter description" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[{ required: true, message: "Amount is required" }]}
              >
                <Input
                  type="number"
                  placeholder="Enter amount"
                  style={{ textAlign: "right" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={adding}
              disabled={adding}
              icon={<FileAddOutlined />}
            >
              Add Expense
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default Expenses;
