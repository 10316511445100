// errorInterceptor.ts
import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

const addErrorHandlingInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      // Check if response.data.responsecode is 22
      if (response.data && response.data.responseCode === 21) {
        // Redirect to the access denied page
        localStorage.clear();
        window.location.href = '/token-expired'; // Change this to your access denied page URL
      }
      else if (response.data && response.data.responseCode === 22) {
        // Redirect to the access denied page
        window.location.href = '/permission-denied'; // Change this to your access denied page URL
      }
      return response;
    },
    (error: AxiosError) => {
      if (error.response) {
        // Server responded with an error status code
        if (error.response.status === 401) {
          // Handle unauthorized (e.g., redirect to login)
          window.location.href = '/login'; // Change this to your login page URL
        } else if (error.response.status === 403) {
          // Handle forbidden (e.g., redirect to access denied)
          window.location.href = '/permission-denied'; // Change this to your access denied page URL
        }
      } else if (error.request) {
        // Request was made but no response received (e.g., network issue)
        console.error('Network error:', error.request);
      } else {
        // Something else went wrong
        console.error('Error:', error.message);
      }
      return Promise.reject(error);
    }
  );
};

export default addErrorHandlingInterceptor;
