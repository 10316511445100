import React from "react";
import { Column } from "@ant-design/plots";

interface BarChartProps {
  data: any[]; // Data for the chart
  xField: string; // Field for the x-axis
  yField: string; // Field for the y-axis
  colors?: Record<string, string>;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  xField,
  yField,
  colors,
}) => {
  const config = {
    data,
    xField,
    yField,
    colorField: xField, // Use xField for coloring
    color: colors || undefined, // Set colors if provided
    style: {
      radiusTopLeft: 10,
      radiusTopRight: 10,
    },
  };

  return <Column {...config} />;
};

export default BarChart;
