import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Select,
  Button,
  Card,
  Row,
  Col,
  Form,
  Divider,
  DatePicker,
  Spin,
  message,
} from "antd";
import {
  ClearOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  fetchContacts,
  loadInvoiceProducts,
  generateInvoice,
} from "../services/ApiServices/apiFunctions";
import dayjs from "dayjs";
import SearchableSelect from "../components/common/SearchableSelect";

interface Product {
  key: string;
  ProductID: number;
  Product: string;
  Qty: number;
  Rate: number;
  Total: number;
  PartyID: number;
  PayMode: string;
  PartyName: string;
  Description: string;
  InvoiceType: string;
  PurchaseRate: number;
  SalePrice: number;
  InvoiceDate: any;
  isTotalUserInput: boolean;
}

interface ProductsList {
  ID: number;
  BrandName: string;
  Unit: string;
  Qty: number;
  PurchaseRate: number;
  Amount: number;
}
interface Contact {
  id: number;
  name: string;
}
interface InvoiceDetailsProps {
  InvoiceType: string;
}

function InvoiceDetails({ InvoiceType }: InvoiceDetailsProps) {
  const initialProduct: Product = {
    key: "1",
    Product: "",
    ProductID: 0,
    Qty: 0,
    Rate: 0,
    Total: 0,
    PayMode: "CASH",
    PartyName: "WALK IN",
    PartyID: 1,
    Description: "",
    InvoiceType: InvoiceType,
    PurchaseRate: 0,
    SalePrice: 0,
    InvoiceDate: dayjs(),
    isTotalUserInput: false,
  };

  const [form] = Form.useForm(); // Create a form instance
  const [data, setData] = useState<Product[]>([initialProduct]);
  const [loading, setLoading] = useState(true);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [ProductsList, setProductsList] = useState<ProductsList[]>([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [contacts, setContacts] = useState<Contact[]>([]);

  useEffect(() => {
    handleClear();
    form.setFieldsValue({ InvoiceDate: selectedDate });
  }, [form, InvoiceType]);

  const loadContacts = async () => {
    setLoading(true);
    try {
      const data = await fetchContacts();
      if (Array.isArray(data.responseObject)) {
        setContacts(data.responseObject);
        setErrorMessage(null);
      } else {
        setErrorMessage("Error: Invalid data format");
      }
    } catch (error) {
      setErrorMessage("Error fetching contacts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const loadProducts = async () => {
    setLoading(true);
    try {
      const data = await loadInvoiceProducts();
      if (Array.isArray(data.responseObject)) {
        setProductsList(data.responseObject);
        setErrorMessage(null);
      } else {
        setErrorMessage("Error: Invalid data format");
      }
    } catch (error) {
      setErrorMessage("Error fetching products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const handleProductChange = (value: number, key: string) => {
    const selectedProduct = ProductsList.find(
      (product) => product.ID === value
    );
    if (selectedProduct) {
      const updatedData = data.map((item) =>
        item.key === key
          ? {
              ...item,
              ProductID: value,
              Qty: 1,
              SalePrice: selectedProduct.PurchaseRate,
              PurchaseRate: selectedProduct.PurchaseRate,
            }
          : item
      );
      setData(updatedData);
    }
  };
  const handleQuantityChange = (value: number, key: string) => {
    const updatedData = data.map((item) => {
      if (item.key === key) {
        // Check if the InvoiceType is either "Sale Invoice" or "Purchase Return"
        if (
          (InvoiceType === "SALE INVOICE" ||
            InvoiceType === "PURCHASE RETURN") &&
          item.ProductID
        ) {
          const maxQty =
            ProductsList.find((product) => product.ID === item.ProductID)
              ?.Qty ?? 0;

          if (value > maxQty) {
            // Quantity exceeds available stock, show a message or take appropriate action
            message.error(
              `Quantity cannot exceed available stock (${maxQty}).`
            );
            return item;
          }
        }
        const total = value * item.Rate;
        return { ...item, Qty: value, Total: total };
      }

      return item;
    });

    setData(updatedData);
  };

  const handleRateChange = (value: number, key: string) => {
    const updatedData = data.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          Rate: value,
          isTotalUserInput: false, // Reset the flag when Rate changes
          Total: !item.isTotalUserInput ? value * item.Qty : item.Total, // Update Total only if it wasn't changed by the user
        };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleTotalChange = (value: string, key: string) => {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      // Handle invalid input, e.g., non-numeric characters
      return;
    }

    const updatedData = data.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          Total: numericValue,
          isTotalUserInput: true, // Set the flag to true for user input
          Rate: numericValue / item.Qty, // Update Rate based on Total and Qty
        };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleAddRow = () => {
    const newRowKey = (data.length + 1).toString();
    const newRow: Product = {
      ...initialProduct,
      key: newRowKey,
    };
    const updatedData = [...data, newRow];
    setData(updatedData);

    // Focus on the newly added row's select element
    setTimeout(() => {
      const newSelectRef = document.getElementById(
        `productSelect_${newRowKey}`
      );
      if (newSelectRef) {
        newSelectRef.focus();
      }
    }, 0);
  };

  const handleDeleteRow = (key: string) => {
    if (data.length > 1) {
      const updatedData = data.filter((item) => item.key !== key);
      setData(updatedData);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      // Log the form values and table data in JSON format
      const values = await form.validateFields();

      // Filter items with a 0 of "ProductID" value
      const validItems = data.filter((item) => item.ProductID !== 0);

      if (validItems.length === 0) {
        // No valid items, show an error message or take appropriate action
        message.error("No valid product selected in invoice.");
        return;
      }

      // Check if any product's quantity, rate, or total is less than or equal to zero
      const invalidProducts = validItems.filter(
        (item) => item.Qty <= 0 || item.Rate <= 0 || item.Total <= 0
      );

      if (invalidProducts.length > 0) {
        // Some products have invalid quantity, rate, or total, show an error message or take appropriate action
        message.error("Quantity, rate, or total cannot be zero or negative.");
        return;
      }

      const formData = {
        PartyID: values.PartyID,
        InvoiceType: InvoiceType,
        Description: values.Description,
        PayMode: values.PayMode,
        InvoiceDate: dayjs(values.InvoiceDate).format("YYYY-MM-DD"),
      };

      // Merge formData values with the valid table data
      const tableDataWithFormData = validItems.map((item) => {
        const total = item.Qty * item.Rate;
        return {
          ...item,
          Product: item.Product || "",
          Rate: item.Rate.toFixed(2),
          Total: total.toFixed(2),
          ...formData, // Merge the formData values into each valid table data item
        };
      });

      const response = await generateInvoice(tableDataWithFormData);
      if (response.responseCode === 0) {
        message.success(response.responseDescription);
        form.setFieldsValue({
          InvoiceNumber: response.responseObject.InvoiceNo,
        }); // Set the initial value of the "InvoiceNumber" input field
        setSubmitSuccess(true);
        setSelectedDate(dayjs(values.InvoiceDate));
      } else {
        message.error(response.responseDescription);
      }
    } catch (error) {
      message.error("Failed to create an invoice");
    } finally {
      setSubmitLoading(false);
    }
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "key",
      key: "key",
      width: 50, // Specify the width for this column
      render: (text: number, record: Product) => (
        <Input
          type="number"
          readOnly
          value={record.key}
          style={{ textAlign: "center" }} // Align to the right
        />
      ),
    },
    {
      title: "Product",
      dataIndex: "ProductID",
      key: "ProductID",
      width: 300, // Specify the width for this column
      render: (text: string, record: Product) => (
        <div>
          {loading ? (
            <Spin tip="Loading.." size="small" />
          ) : (
            <SearchableSelect
              options={ProductsList}
              placeholder="Select a Product"
              onChange={(value) => handleProductChange(+value, record.key)}
              idKey="ID"
              nameKey="BrandName"
            />
          )}
        </div>
      ),
    },
    {
      title: "Qty",
      dataIndex: "Qty",
      key: "Qty",
      width: 100, // Specify the width for this column
      render: (text: number, record: Product) => (
        <Input
          type="number"
          value={record.Qty}
          onChange={(e) => handleQuantityChange(+e.target.value, record.key)}
          style={{ textAlign: "right" }} // Align to the right
        />
      ),
    },
    {
      title: "Rate",
      dataIndex: "Rate",
      key: "Rate",
      width: 100, // Specify the width for this column
      render: (text: number, record: Product) => (
        <Input
          type="number"
          value={record.Rate}
          onChange={(e) => handleRateChange(+e.target.value, record.key)}
          style={{ textAlign: "right" }} // Align to the right
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      width: 100, // Specify the width for this column
      render: (text: number, record: Product) => (
        <Input
          type="number"
          value={record.Total}
          onChange={(e) => handleTotalChange(e.target.value, record.key)}
          style={{ textAlign: "right" }}
        />
      ),
    },

    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text: string, record: Product) =>
        data.length > 1 ? (
          <Button
            type="primary"
            onClick={() => handleDeleteRow(record.key)}
            icon={<MinusCircleOutlined />}
          >
            Delete
          </Button>
        ) : null,
    },
  ];

  const handleClear = () => {
    // Clear the table data and reset form fields
    setData([initialProduct]);
    form.resetFields();
    form.setFieldsValue({ InvoiceDate: selectedDate });
    setSubmitSuccess(false);
    loadContacts();
    loadProducts();
  };

  const handlePayModeChange = (value: string) => {
    // If pay mode is "CASH", set the party ID to 1
    if (value === "CASH") {
      form.setFieldsValue({ PartyID: 1 });
    }
  };

  return (
    <Card
      title={InvoiceType}
      extra={
        <Button type="primary" onClick={handleClear} icon={<ClearOutlined />}>
          Clear
        </Button>
      }
    >
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <Form form={form} onFinish={handleSubmit} initialValues={initialProduct}>
        <Row gutter={16} justify="space-between">
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item name="InvoiceNumber" label="Invoice Number">
              <Input readOnly placeholder="Invoice Number" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 12 }} md={{ span: 12 }}>
            <Form.Item name="PayMode" label="Pay Mode">
              <Select
                defaultValue="CASH"
                options={[
                  { value: "CASH", label: "CASH" },
                  { value: "CREDIT", label: "CREDIT" },
                ]}
                onChange={handlePayModeChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="space-between">
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name="PartyID"
              label="Select Contact"
              rules={[{ required: true, message: "Contact is required" }]}
            >
              <SearchableSelect
                options={contacts}
                placeholder="Select an contact"
                onChange={(value) => form.setFieldsValue({ PartyID: value })}
                idKey="id"
                nameKey="name"
              />
            </Form.Item>
          </Col>

          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item name="Description" label="Description">
              <Input placeholder="Description" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name="InvoiceDate"
              label="Invoice Date"
              rules={[{ required: true, message: "Date is required" }]}
            >
              <DatePicker defaultValue={selectedDate} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Divider orientation="center">Invoice Details</Divider>
            <Table
              dataSource={data}
              columns={columns}
              pagination={{
                pageSize: 30,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Products`,
              }}
              size="small"
              scroll={{ x: "max-content" }}
              footer={() => <div style={{ textAlign: "right" }}></div>}
              bordered
              summary={(tableData) => {
                let totalQty = 0;
                let gTotal = 0;
                tableData.forEach(({ Qty, Rate }) => {
                  totalQty += Qty;
                  gTotal += Qty * Rate;
                });
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="right">
                        <strong>Grand Total</strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Input
                          type="number"
                          readOnly
                          value={totalQty.toFixed(2)}
                          style={{ textAlign: "right" }}
                        />
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Input
                          type="number"
                          readOnly
                          value={gTotal.toFixed(2)}
                          style={{ textAlign: "right" }}
                        />
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Button
                          type="primary"
                          onClick={handleAddRow}
                          icon={<PlusCircleOutlined />}
                        >
                          Add
                        </Button>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
        <Button
          type="primary"
          style={{ marginTop: "10px" }}
          loading={submitLoading}
          htmlType="submit" // Submit the form
          icon={<SaveOutlined />}
          disabled={submitSuccess} // Disable the submit button if response was successful
        >
          Submit
        </Button>
      </Form>
    </Card>
  );
}

export default InvoiceDetails;
