import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Input,
  Button,
  Popconfirm,
  message,
  Card,
  Modal,
  Tooltip,
} from "antd";
import {
  fetchCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../services/ApiServices/apiFunctions"; // Replace with your actual API functions
import {
  EditOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

const { Column } = Table;

interface Category {
  id: number;
  CategoryName: string;
}

function ProductCategory() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    setLoading(true);
    try {
      const data = await fetchCategories();
      if (Array.isArray(data.responseObject)) {
        setCategories(data.responseObject);
        setErrorMessage(null);
      } else {
        console.error("Invalid data format:", data);
        setErrorMessage("Error: Invalid data format");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setErrorMessage("Error fetching categories. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddCategory = async (values: any) => {
    values.CategoryName = values.CategoryName.toUpperCase();
    setAdding(true);
    try {
      const response = await createCategory(values);
      if (response.responseCode === 0) {
        message.success("Category added successfully");
        loadCategories();
        form.resetFields();
      } else {
        message.error(response.responseObject.CategoryName);
      }
    } catch (error) {
      console.error("Error adding category:", error);
    } finally {
      setAdding(false);
    }
  };

  const handleUpdateCategory = async (values: any) => {
    values.CategoryName = values.CategoryName.toUpperCase();
    if (selectedCategory) {
      setUpdating(true);
      try {
        const response = await updateCategory(selectedCategory.id, values);
        if (response.responseCode === 0) {
          message.success("Category updated successfully");
          loadCategories();
          setUpdateModalVisible(false);
          form.resetFields();
        } else {
          message.error("Failed to update category");
        }
      } catch (error) {
        console.error("Error updating category:", error);
      } finally {
        setUpdating(false);
      }
    }
  };

  const handleDeleteCategory = async (id: number) => {
    setDeleting(true);
    try {
      const response = await deleteCategory(id);
      if (response.responseCode === 0) {
        message.success("Category deleted successfully");
        loadCategories();
      } else {
        message.error("Failed to delete category");
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    } finally {
      setDeleting(false);
    }
  };

  const showUpdateModal = (category: Category) => {
    setSelectedCategory(category);
    form.setFieldsValue(category);
    setUpdateModalVisible(true);
  };

  const hideUpdateModal = () => {
    setSelectedCategory(null);
    form.resetFields();
    setUpdateModalVisible(false);
  };

  return (
    <div>
      <Card title="Product Category Management">
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Form
          form={form}
          onFinish={handleAddCategory}
          layout="vertical"
          size="middle"
        >
          <Form.Item
            name="CategoryName"
            label="Category Name"
            rules={[{ required: true, message: "Category name is required" }]}
          >
            <Input placeholder="Enter category name" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={adding}
              disabled={adding || updating || deleting}
              icon={<FileAddOutlined />}
            >
              Add Category
            </Button>
          </Form.Item>
        </Form>
        <Table
          dataSource={categories}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize: 10,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} records`,
          }}
          size="small"
          scroll={{ x: true }}
          bordered
        >
          <Column
            title="Category Name"
            dataIndex="CategoryName"
            key="CategoryName"
          />
          <Column
            title="Actions"
            key="actions"
            width="20%"
            render={(text, record: Category) => (
              <div>
                <Tooltip title="Edit">
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => showUpdateModal(record)}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you sure you want to delete this category?"
                    onConfirm={() => handleDeleteCategory(record.id)}
                  >
                    <Button type="link" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          />
        </Table>
        <Modal
          title="Update Category"
          style={{ top: 20 }}
          open={isUpdateModalVisible}
          onOk={() => form.submit()}
          onCancel={hideUpdateModal}
          confirmLoading={updating}
        >
          <Form
            form={form}
            onFinish={handleUpdateCategory}
            layout="vertical"
            size="small"
          >
            <Form.Item
              name="CategoryName"
              label="Category Name"
              rules={[{ required: true, message: "Category name is required" }]}
            >
              <Input placeholder="Enter category name" />
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </div>
  );
}

export default ProductCategory;
