import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Input,
  Button,
  Popconfirm,
  message,
  Card,
  Modal,
  Tooltip,
} from "antd";
import {
  fetchExpenseCategories, // Update the API function names
  createExpenseCategory, // Update the API function names
  updateExpenseCategory, // Update the API function names
  deleteExpenseCategory, // Update the API function names
} from "../services/ApiServices/apiFunctions"; // Replace with your actual API functions
import {
  EditOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

const { Column } = Table;

interface ExpenseCategory {
  id: number;
  CategoryName: string;
}

function ExpenseCategory() {
  // Update the function name
  const [expenseCategories, setExpenseCategories] = useState<ExpenseCategory[]>(
    []
  );
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [selectedExpenseCategory, setSelectedExpenseCategory] =
    useState<ExpenseCategory | null>(null);

  useEffect(() => {
    loadExpenseCategories(); // Update method name
  }, []);

  const loadExpenseCategories = async () => {
    // Update method name
    setLoading(true);
    try {
      const data = await fetchExpenseCategories(); // Update the API function names
      if (Array.isArray(data.responseObject)) {
        setExpenseCategories(data.responseObject);
        setErrorMessage(null);
      } else {
        console.error("Invalid data format:", data);
        setErrorMessage("Error: Invalid data format");
      }
    } catch (error) {
      console.error("Error fetching expense categories:", error);
      setErrorMessage(
        "Error fetching expense categories. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAddExpenseCategory = async (values: any) => {
    // Update method name
    values.CategoryName = values.CategoryName.toUpperCase(); // Change to "CategoryName"
    setAdding(true);
    try {
      const response = await createExpenseCategory(values); // Update the API function names
      if (response.responseCode === 0) {
        message.success("Expense category added successfully");
        loadExpenseCategories(); // Update method name
        form.resetFields();
      } else {
        message.error(response.responseObject.CategoryName); // Change to "CategoryName"
      }
    } catch (error) {
      console.error("Error adding expense category:", error);
    } finally {
      setAdding(false);
    }
  };

  const handleUpdateExpenseCategory = async (values: any) => {
    // Update method name
    values.CategoryName = values.CategoryName.toUpperCase(); // Change to "CategoryName"
    if (selectedExpenseCategory) {
      setUpdating(true);
      try {
        const response = await updateExpenseCategory(
          selectedExpenseCategory.id,
          values
        ); // Update the API function names
        if (response.responseCode === 0) {
          message.success("Expense category updated successfully");
          loadExpenseCategories(); // Update method name
          setUpdateModalVisible(false);
          form.resetFields();
        } else {
          message.error("Failed to update expense category");
        }
      } catch (error) {
        console.error("Error updating expense category:", error);
      } finally {
        setUpdating(false);
      }
    }
  };

  const handleDeleteExpenseCategory = async (id: number) => {
    // Update method name
    setDeleting(true);
    try {
      const response = await deleteExpenseCategory(id); // Update the API function names
      if (response.responseCode === 0) {
        message.success("Expense category deleted successfully");
        loadExpenseCategories(); // Update method name
      } else {
        message.error("Failed to delete expense category");
      }
    } catch (error) {
      console.error("Error deleting expense category:", error);
    } finally {
      setDeleting(false);
    }
  };

  const showUpdateModal = (expenseCategory: ExpenseCategory) => {
    // Update parameter name
    setSelectedExpenseCategory(expenseCategory); // Update parameter name
    form.setFieldsValue(expenseCategory); // Update parameter name
    setUpdateModalVisible(true);
  };

  const hideUpdateModal = () => {
    setSelectedExpenseCategory(null);
    form.resetFields();
    setUpdateModalVisible(false);
  };

  return (
    <div>
      <Card title="Expense Category Management">
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Form
          form={form}
          onFinish={handleAddExpenseCategory} // Update method name
          layout="vertical"
          size="middle"
        >
          <Form.Item
            name="CategoryName" // Change to "CategoryName"
            label="Category Name"
            rules={[{ required: true, message: "Category name is required" }]}
          >
            <Input placeholder="Enter category name" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={adding}
              disabled={adding || updating || deleting}
              icon={<FileAddOutlined />}
            >
              Add Category
            </Button>
          </Form.Item>
        </Form>
        <Table
          dataSource={expenseCategories} // Update variable name
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize: 10,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} records`,
          }}
          size="small"
          scroll={{ x: true }}
          bordered
        >
          <Column
            title="Category Name"
            dataIndex="CategoryName" // Change to "CategoryName"
            key="CategoryName"
          />
          <Column
            title="Actions"
            key="actions"
            width="20%"
            render={(text, record: ExpenseCategory) => {
              // Update type
              return (
                <div>
                  <Tooltip title="Edit">
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => showUpdateModal(record)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Popconfirm
                      title="Are you sure you want to delete this category?"
                      onConfirm={() => handleDeleteExpenseCategory(record.id)} // Update method name
                    >
                      <Button type="link" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
              );
            }}
          />
        </Table>
        <Modal
          title="Update Category"
          style={{ top: 20 }}
          open={isUpdateModalVisible} // Change "open" to "visible"
          onOk={() => form.submit()}
          onCancel={hideUpdateModal}
          confirmLoading={updating}
        >
          <Form
            form={form}
            onFinish={handleUpdateExpenseCategory} // Update method name
            layout="vertical"
            size="small"
          >
            <Form.Item
              name="CategoryName" // Change to "CategoryName"
              label="Category Name"
              rules={[{ required: true, message: "Category name is required" }]}
            >
              <Input placeholder="Enter category name" />
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </div>
  );
}

export default ExpenseCategory; // Update the export name
