import React from "react";
import "./App.css";
import { AppRouter } from "./routes/AppRouter";
import { BrowserRouter } from "react-router-dom";

//  "homepage":"https://yourstagging-com.stackstaging.com/project/alwancollection",

function App() {
  return (
  //<BrowserRouter basename="/project/alwancollection">
  <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
