class Utilities {
    static copyToClipboard(text: string): void {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea).select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }
  }
  
  export default Utilities;
  