import React, { useState, useEffect } from "react";
import { Card, Table, Button, Input, Select, message } from "antd";
import {
  fetchRegisteredUsers,
  fetchRolesAndBranches,
  approveUser,
} from "../services/ApiServices/apiFunctions";
//import { useParams } from "react-router-dom"; // Assuming you're using React Router

const { Option } = Select;

interface User {
  ID: number;
  FullName: string;
  UserName: string;
  CNIC: number;
  ContactNo: number;
  selectedBranch: number;
  selectedRole: number;
  // Add other properties as needed
}
interface BranchInterface {
  id: number;
  name: string;
  status: number;
  // Add other properties as needed
}
interface RoleInterface {
  id: number;
  name: string;
  status: number;
  // Add other properties as needed
}
function UsersList() {
  //const { userId } = useParams();

  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [error, setError] = useState<string | null>(null); // Add an error state

  const [branches, setBranches] = useState<BranchInterface[]>([]);
  const [roles, setRoles] = useState<RoleInterface[]>([]);

  useEffect(() => {
    fetchUsers();
    fetchDropDowns();
  }, []);

  async function fetchUsers() {
    try {
      // Set loading to true while fetching data
      setLoading(true);
      setError(null);

      // Call the new API function
      const userData = await fetchRegisteredUsers();
      setUsers(userData.responseObject);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      // Handle errors by setting the error message and setting loading to false
      setError(
        "An error occurred while fetching users. Please try again later."
      );
      setLoading(false);
    }
  }

  async function fetchDropDowns() {
    try {
      const response = await fetchRolesAndBranches();
      setBranches(response.responseObject.branches);
      setRoles(response.responseObject.roles);
    } catch (error) {
      console.error("Error fetching dropdowns:", error);
    }
  }

  const columns = [
    {
      title: "Serial No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text: any, record: User, index: number) => (
        <div style={{ textAlign: "center" }}>{index + 1}</div>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "FullName",
      key: "FullName",
    },
    {
      title: "User Name",
      dataIndex: "UserName",
      key: "UserName",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (text: string, record: User) => (
        <Select
          defaultValue={text}
          style={{ width: 120 }}
          onChange={(value) => handleBranchSelect(record.ID, parseInt(value))}
        >
          {branches.map((branch) => (
            <Option key={branch.id} value={branch.id}>
              {branch.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text: string, record: User) => (
        <Select
          defaultValue={text}
          style={{ width: 120 }}
          onChange={(value) => handleRoleSelect(record.ID, parseInt(value))}
        >
          {roles.map((role) => (
            <Option key={role.id} value={role.id}>
              {role.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: User) => {
        const isButtonDisabled = !record.selectedBranch || !record.selectedRole;

        return (
          <Button
            type="primary"
            onClick={() =>
              approveUsers(
                record.UserName,
                record.selectedBranch,
                record.selectedRole
              )
            }
            disabled={isButtonDisabled}
          >
            Approve
          </Button>
        );
      },
    },
  ];

  async function approveUsers(
    username: string,
    branchID: number,
    roleID: number
  ) {
    const user = users.find((user) => user.UserName === username);
    if (!user) {
      console.error("User not found with username:", username);
      return;
    }
    const requestBody = {
      approvedBy: localStorage.getItem("userID"),
      userID: user.ID,
      branchID: branchID,
      roleID: roleID,
    };
    approveUserss(requestBody);
  }

  async function approveUserss(requestBody: any) {
    try {
      const response = await approveUser(requestBody);
      if (response.responseCode === 0) {
        message.success("User approved successfully");
        // Update the users state after approval
        fetchUsers();
        fetchDropDowns();
      } else {
        setError(response.responseDescription);
      }
    } catch (error) {
      console.error("Error approving user:", error);
      // Handle error and show an error message if needed
    }
  }
  const handleSearch = (value: string) => {
    setSearchText(value);
  };
  // Helper functions to update selected branch and role for each row
  const handleBranchSelect = (userId: number, branchId: number) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.ID === userId) {
          return { ...user, selectedBranch: branchId };
        }
        return user;
      })
    );
  };

  const handleRoleSelect = (userId: number, roleId: number) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.ID === userId) {
          return { ...user, selectedRole: roleId };
        }
        return user;
      })
    );
  };

  let filteredUsers: User[] = [];
  if (Array.isArray(users)) {
    filteredUsers = users.filter((user) =>
      user.FullName.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  return (
    <Card title="Users List">
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Input.Search
        placeholder="Search by Full Name"
        onSearch={handleSearch}
        style={{ marginBottom: 16 }}
      />
      {users.length > 0 ? (
        <Table
          dataSource={filteredUsers}
          columns={columns}
          loading={loading}
          rowKey="id"
          pagination={{ pageSize: 10 }}
          size="small"
          scroll={{ x: true }} // Enable horizontal scrolling for small screens
        />
      ) : (
        !loading && <p>No users available.</p>
      )}
    </Card>
  );
}

export default UsersList;
