import React, { useState, useEffect } from "react";
import { Table, Button, Alert, DatePicker, Card } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  fetchAccountLedger,
  fetchAccounts,
} from "../services/ApiServices/apiFunctions";
import SearchableSelect from "../components/common/SearchableSelect";

interface Account {
  id: number;
  account_name: string;
  account_type: string;
  parent_id: number;
}

interface LedgerEntry {
  voucher_number: number;
  voucher_date: string;
  description: string;
  voucher_type: string;
  pay_mode: string;
  cheque_no: string;
  document_number: string;
  user_id: string;
  account_name: string;
  opening_balance: number;
  debit: number;
  credit: number;
  closing_balance: number;
}

const LedgerView: React.FC = () => {
  const [data, setData] = useState<LedgerEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<number>(0);
  const [fromDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [toDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [error, setError] = useState<string | null>(null);
  const [datesSelected, setDatesSelected] = useState<boolean>(false);
  const [openingBalance, setOpeningBalance] = useState<number>(0.0);
  const [closingBalance, setClosingBalance] = useState<number>(0.0);

  useEffect(() => {
    fetchAccountsData();
  }, []);

  const fetchAccountsData = async (): Promise<void> => {
    try {
      const accountsData: Account[] = await fetchAccounts();
      const filteredAccounts = accountsData.filter((account) =>
        [1, 2, 3, 4].includes(account.parent_id)
      );
      setAccounts(filteredAccounts);
    } catch (error) {
      setError("Error while fetching accounts");
    }
  };

  const fetchLedgerData = async (
    page: number,
    pageSize: number,
    fromDateParam: dayjs.Dayjs | null,
    toDateParam: dayjs.Dayjs | null,
    selectedAccount: number
  ) => {
    setLoading(true);
    setError(null);
    try {
      const fromDateValue = fromDateParam
        ? fromDateParam.format("YYYY-MM-DD")
        : undefined;
      const toDateValue = toDateParam
        ? toDateParam.format("YYYY-MM-DD")
        : undefined;
      setOpeningBalance(0.0);
      setClosingBalance(0.0);

      const response = await fetchAccountLedger(
        page,
        pageSize,
        fromDateValue,
        toDateValue,
        selectedAccount
      );

      if (
        Array.isArray(response.responseObject.data) &&
        response.responseObject.data.length > 0
      ) {
        setData(response.responseObject.data);
        setPagination({ ...pagination, total: response.total });
        setOpeningBalance(response.responseObject.data[0].opening_balance);
        const lastIndex = response.responseObject.data.length - 1;
        setClosingBalance(
          response.responseObject.data[lastIndex].closing_balance
        );
      } else {
        // Clear data when there is no data in the response
        setData([]);
        setPagination({ ...pagination, total: 0 });
        setOpeningBalance(0.0);
        setClosingBalance(0.0);
      }
    } catch (error) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    setStartDate(date);
    setDatesSelected(!!date && !!toDate);
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    setEndDate(date);
    setDatesSelected(!!date && !!fromDate);
  };

  const handleFilterClick = () => {
    if (!selectedAccount) {
      setError("Please select an account.");
      return;
    }
    if (!datesSelected) {
      setError("Please select both start and end dates.");
      return;
    }

    fetchLedgerData(
      pagination.current,
      pagination.pageSize,
      fromDate,
      toDate,
      selectedAccount
    );
  };

  const columns = [
    {
      title: "Voucher Number",
      dataIndex: "voucher_number",
      key: "voucher_number",
    },
    { title: "Voucher Date", dataIndex: "voucher_date", key: "voucher_date" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Cheque Number", dataIndex: "cheque_no", key: "cheque_no" },
    {
      title: "Debit/Received",
      dataIndex: "debit",
      key: "debit",
    },
    {
      title: "Credit/Payment",
      dataIndex: "credit",
      key: "credit",
    },
    {
      title: "Balance",
      dataIndex: "closing_balance",
      key: "closing_balance",
      render: (text: number) => (
        <div style={{ textAlign: "right", color: text < 0 ? "red" : "black" }}>
          {text < 0 ? `(${Math.abs(text)})` : text}
        </div>
      ),
    },
  ];

  return (
    <Card title="Account Ledger">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <SearchableSelect
            options={accounts}
            placeholder="Select an Account"
            onChange={(value) => setSelectedAccount(value)}
            idKey="id"
            nameKey="account_name"
          />
        </div>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <DatePicker
            onChange={handleStartDateChange}
            style={{ width: "100%" }}
            placeholder="From Date"
          />
        </div>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <DatePicker
            onChange={handleEndDateChange}
            style={{ width: "100%" }}
            placeholder="To Date"
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Button
            type="primary"
            onClick={handleFilterClick}
            icon={<FilterOutlined />}
            disabled={!selectedAccount || !datesSelected}
          >
            Filter
          </Button>
        </div>
      </div>

      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}

      {openingBalance !== null && openingBalance !== undefined && (
        <div
          style={{
            marginBottom: "10px",
            textAlign: "right",
            marginTop: "10px",
            color: openingBalance < 0 ? "red" : "black",
          }}
        >
          Opening Balance:{" "}
          {openingBalance < 0
            ? `(${Math.abs(openingBalance)})`
            : openingBalance}
        </div>
      )}

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey="voucher_id"
        size="small"
        bordered
      />
      {closingBalance !== null && closingBalance !== undefined && (
        <div
          style={{
            textAlign: "right",
            marginTop: "10px",
            color: closingBalance < 0 ? "red" : "black",
          }}
        >
          Closing Balance:{" "}
          {closingBalance < 0
            ? `(${Math.abs(closingBalance)})`
            : closingBalance}
        </div>
      )}
    </Card>
  );
};

export default LedgerView;
