import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Select,
  Button,
  Space,
  Alert,
  DatePicker,
  Card,
} from "antd";
import {
  SearchOutlined,
  PrinterOutlined,
  EyeOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { fetchInvoicesReport } from "../services/ApiServices/apiFunctions";
import dayjs from "dayjs";
import InvoiceView from "./InvoiceView";

const { RangePicker } = DatePicker;

interface DataItem {
  InvoiceNo: string;
  InvoiceType: string;
  Description: string | null;
  PartyName: string;
  InvoiceDate: string;
  PayMode: string;
  totalSales: string;
}
const ViewInvoices: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [InvoiceType, setInvoiceType] = useState<string>("PURCHASE INVOICE");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [error, setError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);

  useEffect(() => {
    fetchTableData(
      pagination.current,
      pagination.pageSize,
      searchText,
      dateRange
    );
  }, [pagination.current, pagination.pageSize, searchText, dateRange]);

  const fetchTableData = async (
    page: number,
    pageSize: number,
    searchText: string,
    dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  ) => {
    setLoading(true);
    setError(null);
    try {
      const fromDate = dateRange
        ? dateRange[0]?.format("YYYY-MM-DD")
        : undefined;
      const toDate = dateRange ? dateRange[1]?.format("YYYY-MM-DD") : undefined;

      const response = await fetchInvoicesReport(
        page,
        pageSize,
        searchText,
        fromDate,
        toDate,
        InvoiceType
      );

      // Simulate pagination
      //  const startIndex = (page - 1) * pageSize;
      //  const endIndex = startIndex + pageSize;
      //  const paginatedData = response.responseObject.data

      setData(response.responseObject.invoiceData.data);
      setGrandTotal(response.responseObject.totalSum);
      setPagination({
        ...pagination,
        total: response.responseObject.invoiceData.total,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
  };

  const handleDateRangeChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    setDateRange(dates);
    setPagination({ ...pagination, current: 1 });
  };

  const handleView = (record: DataItem) => {
    console.log("View button clicked", record);
    setSelectedInvoice(record.InvoiceNo);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedInvoice(null);
  };

  const handlePrint = (record: DataItem) => {
    console.log("Printing:", record);
  };

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
    },
    {
      title: "Invoice Type",
      dataIndex: "InvoiceType",
      key: "InvoiceType",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Party Name",
      dataIndex: "PartyName",
      key: "PartyName",
    },
    {
      title: "Invoice Date",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
    },
    {
      title: "Pay Mode",
      dataIndex: "PayMode",
      key: "PayMode",
    },
    {
      title: "Amount",
      dataIndex: "TotalSales",
      key: "TotalSales",
      render: (text: string) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: DataItem) => (
        <Space size="middle">
          <Button icon={<EyeOutlined />} onClick={() => handleView(record)} />
          <Button
            icon={<PrinterOutlined />}
            onClick={() => handlePrint(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Card title="View Invoices">
      <InvoiceView
        invoiceNumber={selectedInvoice || ""}
        InvoiceType={InvoiceType}
        visible={modalVisible}
        onClose={handleCloseModal}
      />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <Select
            aria-labelledby="Invoice Type"
            menuItemSelectedIcon
            defaultValue="PURCHASE INVOICE"
            style={{ width: "100%" }}
            onChange={(value) => setInvoiceType(value)}
            options={[
              { value: "PURCHASE INVOICE", label: "PURCHASE INVOICE" },
              { value: "PURCHASE RETURN", label: "PURCHASE RETURN" },
              { value: "SALE INVOICE", label: "SALE INVOICE" },
              { value: "SALE RETURN", label: "SALE RETURN" },
            ]}
          ></Select>
        </div>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <RangePicker
            onChange={handleDateRangeChange}
            style={{ width: "100%" }}
            placeholder={["From Date", "To Date"]}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Button
            type="primary"
            onClick={() =>
              fetchTableData(
                pagination.current,
                pagination.pageSize,
                searchText,
                dateRange
              )
            }
            icon={<FilterOutlined />}
          >
            Filter
          </Button>
        </div>
      </div>

      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey="InvoiceNo"
        size="small"
        scroll={{ x: true }} // Enable horizontal scrolling for small screens
        bordered
        summary={(tableData) => {
          //let totalQty = 0;
          //let gTotal = 0;
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  <strong>Grand Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="right">
                  <strong>{grandTotal}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Card>
  );
};

export default ViewInvoices;
