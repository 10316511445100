import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Input,
  Button,
  Select,
  Popconfirm,
  message,
  Card,
  Modal,
  Tooltip,
  Col,
  Row,
} from "antd";
import {
  fetchContacts,
  createContact,
  updateContact,
  deleteContact,
} from "../services/ApiServices/apiFunctions";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

const { Column } = Table;
const { Search } = Input;
const { Option } = Select;

interface Contact {
  id: number;
  name: string;
  email: string;
  contact_person: string;
  mobile_no: string;
  nic_no: string;
  address: string;
  type: string;
}

function Contacts() {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [searchText, setSearchText] = useState<string>("");

  const [modalForm] = Form.useForm();

  useEffect(() => {
    loadContacts();
  }, []);

  const loadContacts = async () => {
    setLoading(true);
    try {
      const data = await fetchContacts();
      if (Array.isArray(data.responseObject)) {
        setContacts(data.responseObject);
        setErrorMessage(null);
      } else {
        setErrorMessage("Error: Invalid data format");
      }
    } catch (error) {
      setErrorMessage("Error fetching contacts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddContact = async (values: any) => {
    setAdding(true);
    try {
      const response = await createContact(values);
      if (response.responseCode === 0) {
        message.success("Contact added successfully");
        loadContacts();
        form.resetFields();
      } else {
        message.error("Failed to add contact");
      }
    } catch (error) {
      console.error("Error adding contact:", error);
      message.error("Failed to add contact");
    } finally {
      setAdding(false);
    }
  };

  const handleUpdateContact = async (values: any) => {
    if (selectedContact) {
      setUpdating(true);
      try {
        const response = await updateContact(selectedContact.id, values);
        if (response.responseCode === 0) {
          message.success("Contact updated successfully");
          loadContacts();
          setUpdateModalVisible(false);
        } else {
          message.error("Failed to update contact");
        }
      } catch (error) {
        console.error("Error updating contact:", error);
        message.error("Failed to update contact");
      } finally {
        setUpdating(false);
      }
    }
  };

  const handleDeleteContact = async (id: number) => {
    setDeleting(true);
    try {
      const res = await deleteContact(id);
      if (res.responseCode === 0) {
        message.success("Contact deleted successfully");
        loadContacts();
      } else {
        message.error("Failed to delete contact");
      }
    } catch (error) {
      console.error("Error deleting contact:", error);
      message.error("Failed to delete contact");
    } finally {
      setDeleting(false);
    }
  };

  const showUpdateModal = (contact: Contact) => {
    setSelectedContact(contact);
    modalForm.setFieldsValue(contact); // Set initial values for the modal form
    setUpdateModalVisible(true);
  };

  const hideUpdateModal = () => {
    setSelectedContact(null);
    modalForm.resetFields(); // Clear the modal form
    setUpdateModalVisible(false);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const filteredContacts = contacts.filter((contact) =>
    Object.values(contact).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <div>
      <Card title="Contact Management">
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Form
          form={form}
          onFinish={handleAddContact}
          layout="vertical"
          size="middle"
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: "Name is required" },
                  {
                    max: 255,
                    message: "Name should be less than 255 characters",
                  },
                ]}
              >
                <Input placeholder="Enter name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Email is required" },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                  {
                    max: 255,
                    message: "Email should be less than 255 characters",
                  },
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="contact_person"
                label="Contact Person"
                rules={[
                  { required: true, message: "Contact person is required" },
                ]}
              >
                <Input placeholder="Enter contact person" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="mobile_no"
                label="Mobile No"
                rules={[
                  { required: true, message: "Mobile number is required" },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Please enter a valid mobile number",
                  },
                  {
                    max: 15,
                    message: "Mobile number should not exceed 15 digits",
                  },
                ]}
              >
                <Input type="tel" placeholder="Enter mobile number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="nic_no"
                label="NIC No"
                rules={[
                  { required: true, message: "NIC number is required" },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Please enter a valid NIC number",
                  },
                  {
                    max: 15,
                    message: "NIC number should not exceed 15 digits",
                  },
                ]}
              >
                <Input placeholder="Enter NIC number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: "Type is required" }]}
              >
                <Select placeholder="Select type">
                  <Option value="Customer">Customer</Option>
                  <Option value="Vendor">Vendor</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="address"
                label="Address"
                rules={[{ required: true, message: "Address is required" }]}
              >
                <Input.TextArea placeholder="Enter address" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={adding}
              disabled={adding || updating || deleting}
              icon={<FileAddOutlined />}
            >
              Add Contact
            </Button>
          </Form.Item>
        </Form>
        <Search
          placeholder="Search contacts"
          onSearch={handleSearch}
          style={{ width: 200, float: "right", marginBottom: 10 }}
        />
        <Table
          dataSource={filteredContacts}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize: 10,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} records`,
          }}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Email" dataIndex="email" key="email" />
          <Column
            title="Contact Person"
            dataIndex="contact_person"
            key="contactPerson"
          />
          <Column title="Mobile No" dataIndex="mobile_no" key="mobileNo" />
          <Column title="NIC No" dataIndex="nic_no" key="nicNo" />
          <Column title="Address" dataIndex="address" key="address" />
          <Column title="Type" dataIndex="type" key="type" />
          <Column
            title="Actions"
            key="actions"
            width="20%"
            render={(text, record: Contact) => (
              <div>
                <Tooltip title="Edit">
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => showUpdateModal(record)}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you sure you want to delete this contact?"
                    onConfirm={() => handleDeleteContact(record.id)}
                  >
                    <Button type="link" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          />
        </Table>
        <Modal
          title="Update Contact"
          style={{ top: 20 }}
          open={isUpdateModalVisible}
          onOk={modalForm.submit}
          onCancel={hideUpdateModal}
          confirmLoading={updating}
        >
          <Form
            form={modalForm}
            onFinish={handleUpdateContact}
            initialValues={{}}
            layout="vertical"
            size="small"
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: "Name is required" },
                {
                  max: 255,
                  message: "Name should be less than 255 characters",
                },
              ]}
            >
              <Input placeholder="Enter name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Email is required" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
                {
                  max: 255,
                  message: "Email should be less than 255 characters",
                },
              ]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>

            <Form.Item
              name="contact_person"
              label="Contact Person"
              rules={[
                { required: true, message: "Contact person is required" },
                {
                  max: 255,
                  message: "Contact person should be less than 255 characters",
                },
              ]}
            >
              <Input placeholder="Enter contact person" />
            </Form.Item>

            <Form.Item
              name="mobile_no"
              label="Mobile No"
              rules={[
                { required: true, message: "Mobile number is required" },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please enter a valid mobile number",
                },
                {
                  max: 15,
                  message: "Mobile number should not exceed 15 digits",
                },
              ]}
            >
              <Input type="tel" placeholder="Enter mobile number" />
            </Form.Item>

            <Form.Item
              name="nic_no"
              label="NIC No"
              rules={[
                { required: true, message: "NIC number is required" },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please enter a valid NIC number",
                },
                { max: 15, message: "NIC number should not exceed 15 digits" },
              ]}
            >
              <Input placeholder="Enter NIC number" />
            </Form.Item>

            <Form.Item
              name="address"
              label="Address"
              rules={[
                { required: true, message: "Address is required" },
                {
                  max: 255,
                  message: "Address should be less than 255 characters",
                },
              ]}
            >
              <Input.TextArea placeholder="Enter address" />
            </Form.Item>

            <Form.Item
              name="type"
              label="Type"
              rules={[
                { required: true, message: "Type is required" },
                {
                  max: 255,
                  message: "Type should be less than 255 characters",
                },
              ]}
            >
              <Select placeholder="Select type">
                <Option value="Customer">Customer</Option>
                <Option value="Vendor">Vendor</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </div>
  );
}

export default Contacts;
