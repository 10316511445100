import React from "react";
import { Button, Dropdown, Input, Layout, Menu, theme } from "antd";
import {
  UserOutlined,
  HomeOutlined,
  DownOutlined,
  ProfileOutlined,
  EyeOutlined,
  KeyOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import "./HeaderContent.css";
import LogoutButton from "../common/LogoutButton/LogoutButtons";
import { Link } from "react-router-dom";
const { Header } = Layout;

function HeaderContent() {
  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();

  const handleMenuClick = (e: any) => {};

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <span>
          <Link to="/dashboard/user-profile">
            <ProfileOutlined style={{ marginRight: 8, color: colorPrimary }} />
            User Profile
          </Link>
        </span>
      </Menu.Item>
      <Menu.Item key="2">
        <span>
          <Link to="/dashboard/change-password">
            <EyeOutlined style={{ marginRight: 8, color: colorPrimary }} />
            Change Password
          </Link>
        </span>
      </Menu.Item>
      <Menu.Item key="4">
        <span>
          <Link to="/dashboard/generate-admin-token">
            <KeyOutlined style={{ marginRight: 8, color: colorPrimary }} />
            Generate Admin Token
          </Link>
        </span>
      </Menu.Item>
      <Menu.Item key="5">
        <span>
          <Link to="/dashboard/users-list">
            <UserSwitchOutlined
              style={{ marginRight: 8, color: colorPrimary }}
            />
            Approve Users
          </Link>
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ padding: 0, background: colorBgContainer }}>
      <div className="headContent">
        {/* Home button */}
        <Link to="/dashboard/home">
          <Button type="link" icon={<HomeOutlined />}>
            Home
          </Button>
        </Link>
        {/* Search textbox */}
        <Input.Search
          placeholder="Search"
          style={{ flex: 1, marginRight: 16 }}
        />

        {/* Dropdown menu */}
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="link">
            <UserOutlined />
            <DownOutlined />
          </Button>
        </Dropdown>

        {/* Logout button */}
        <LogoutButton />
      </div>
    </Header>
  );
}

export default HeaderContent;
