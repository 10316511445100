import React, { useState } from "react";
import { Form, Input, Button, Card, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import {
  resetPassword,
  changePassword,
} from "../services/ApiServices/apiFunctions";

interface ChangePasswordFormProps {
  title: string;
  showOldPassword: boolean;
  showToken: boolean;
  usernameReadOnly: boolean;
  showNote: boolean;
}

function ChangePasswordForm({
  title,
  showOldPassword,
  showToken,
  usernameReadOnly,
  showNote,
}: ChangePasswordFormProps) {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [processing, setProcessing] = useState(false);

  async function handlePasswordChange(values: any) {
    if (values.Password !== values.Password_confirmation) {
      setErrorMessage("New password and confirm password must match.");
      return;
    } else {
      setErrorMessage("");
    }
    try {
      setProcessing(true);
      let response;
      if (showOldPassword) {
        values.username = localStorage.getItem("UserName");
        response = await changePassword(values);
      } else {
        response = await resetPassword(values);
      }
      if (response.responseCode === 0) {
        setSuccessMessage("Password changed successfully.");
        form.resetFields();
      } else if (response.responseCode === 15) {
        setErrorMessage(response.responseObject);
        setSuccessMessage("");
      } else {
        setErrorMessage(response.responseDescription);
        setSuccessMessage("");
      }
    } catch (error: any) {
      if (error.request) {
        message.error("Server is not connected!");
      } else {
        message.error("An error has occurred");
      }
    } finally {
      setProcessing(false);
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
  };
  return (
    <Card title={title}>
      {showNote && (
        <p>
          Note: If you have forgotten your password, you can request the Admin
          to provide you a token that can be used to create the new password of
          your account.
        </p>
      )}
      <Form
        {...formItemLayout}
        form={form}
        onFinish={handlePasswordChange}
        layout="vertical"
        size="middle"
        initialValues={{
          UserName: usernameReadOnly ? localStorage.getItem("UserName") : "",
          oldPassword: "",
          Password: "",
          Password_confirmation: "",
          Token: "",
        }}
      >
        <Form.Item
          label="User Name"
          name="UserName"
          rules={[
            {
              required: true,
              message: `Please enter your username`,
            },
          ]}
        >
          <Input readOnly={usernameReadOnly} />
        </Form.Item>
        {showOldPassword && (
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: `Please enter your old Password`,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        )}
        {showToken && (
          <Form.Item
            label="Token"
            name="Token"
            rules={[
              {
                required: true,
                message: `Please enter your Token`,
              },
            ]}
          >
            <Input placeholder="Token" />
          </Form.Item>
        )}
        <Form.Item
          label="New Password"
          name="Password"
          rules={[
            {
              required: true,
              message: "Please enter your new password",
            },
            {
              min: 8,
              message: "Password must be at least 8 characters long",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="Password_confirmation"
          rules={[
            {
              required: true,
              message: "Please confirm your new password",
            },
            {
              min: 8,
              message: "Password must be at least 8 characters long",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
        <Form.Item>
          <Button
            icon={<LockOutlined />}
            type="primary"
            htmlType="submit"
            loading={processing}
          >
            {processing ? "Processing..." : "Change Password"}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default ChangePasswordForm;
