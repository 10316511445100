import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Avatar, message } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Form, Input, Button, Card, Row, Col, Typography } from "antd";
import { API_BASE_URL, Logo } from "../../Config";
import "./Login.css";
//import { toHaveFormValues } from "@testing-library/jest-dom/matchers";

const { Item } = Form;
const { Title } = Typography;
const { Text } = Typography;

interface LoginFormValues {
  UserName: string;
  Password: string;
}

function Login() {
  const [processing, setProcessing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if there is a token in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // If there is a token, navigate to the dashboard
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleLogin = async (values: LoginFormValues) => {
    try {
      setProcessing(true);
      const response = await axios.post(`${API_BASE_URL}/login`, values);
      if (response.data.responseCode === 0) {
        // If login is successful
        message.success("Login successful!");
        localStorage.setItem("token", response.data.responseObject.Token);
        localStorage.setItem("userID", response.data.responseObject.ID);
        localStorage.setItem("UserName", response.data.responseObject.UserName);
        localStorage.setItem("FullName", response.data.responseObject.FullName);
        localStorage.setItem("Role", response.data.responseObject.Role);
        localStorage.setItem("CNIC", response.data.responseObject.CNIC);
        localStorage.setItem(
          "ContactNo",
          response.data.responseObject.ContactNo
        );

        navigate("/dashboard");
      } else {
        // navigate("/dashboard");
        message.error(response.data.responseDescription);
      }
    } catch (error: any) {
      // Display error message if there is an error in the request
      if (error.request) {
        // The request was made but no response was received
        message.error("Server is not connected!");
      } else {
        // Something else happened in making the request
        message.error("Error occurred during login");
      }
    } finally {
      setProcessing(false);
    }
  };
  // CSS styles for the card with shadow
  const cardStyle = {
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1)",
  };
  return (
    <>
      <Row className="login-container">
        <Col xs={24} sm={16} md={12} lg={7}>
          <Card className="login-box" style={cardStyle}>
            <Title
              level={3}
              className="login-logo"
              style={{ textAlign: "center" }}
            >
              <Avatar src={Logo} shape="circle" alt="Logo" size={200} />
              <br></br>
              <Text>Login to start your session</Text>
            </Title>
            <Form onFinish={handleLogin}>
              <Item
                name="UserName"
                rules={[
                  { required: true, message: "Please enter your UserName" },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="User Name" />
              </Item>
              <Item
                name="Password"
                rules={[
                  { required: true, message: "Please enter your password" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Item>
              <Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={processing}
                >
                  {processing ? "Processing..." : "Login"}
                </Button>
              </Item>
            </Form>
            <p className="mt-3">
              <Link to="/forgot-password">I forgot my password</Link>
            </p>
            <p>
              <Link to="/register" className="text-center">
                Signup to Register!
              </Link>
            </p>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Login;
