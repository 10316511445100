import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Avatar, message } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  PhoneOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import { Form, Input, Button, Card, Row, Col, Typography } from "antd";
import { API_BASE_URL, Logo } from "../../Config";
import "./Login.css";

const { Item } = Form;
const { Title } = Typography;
const { Text } = Typography;

interface RegisterFormValues {
  UserName: string;
  FullName: string;
  CNIC: number;
  ContactNo: number;
  Password: string;
  Password_confirmation: string;
}

function Register() {
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {}, [navigate]);

  const handleRegister = async (values: RegisterFormValues) => {
    try {
      setProcessing(true);
      setSuccessMessage("");
      setErrorMessage("");
      if (values.Password !== values.Password_confirmation) {
        message.warning("Password Not Matched!");
        return;
      }
      const response = await axios.post(`${API_BASE_URL}/register`, values);
      if (response.data.responseCode === 0) {
        // If Register is successful
        setSuccessMessage(response.data.responseDescription);
        message.success(response.data.responseDescription);
        form.resetFields();
      } else if (response.data.responseCode === 15) {
        if (response.data.responseObject.UserName) {
          setErrorMessage(response.data.responseObject.UserName);
        } else if (response.data.responseObject.CNIC) {
          setErrorMessage(response.data.responseObject.CNIC);
        } else if (response.data.responseObject.ContactNo) {
          setErrorMessage(response.data.responseObject.ContactNo);
        }
      } else {
        setErrorMessage(response.data.responseDescription);
      }
    } catch (error: any) {
      if (error.request) {
        // The request was made but no response was received
        message.error("Server is not connected!");
      } else {
        // Something else happened in making the request
        message.error("Error occurred during Register");
      }
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };
  // CSS styles for the card with shadow
  const cardStyle = {
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1)",
  };
  return (
    <>
      <Row className="login-container">
        <Col xs={24} sm={16} md={12} lg={7}>
          <Card className="login-box" style={cardStyle}>
            <Title
              level={3}
              className="login-logo"
              style={{ textAlign: "center" }}
            >
              <Avatar src={Logo} alt="Logo" size={150} />
              <br></br>
              <Text>Fill the form, Complete Registration</Text>
            </Title>
            <Form onFinish={handleRegister} size="small">
              <Item
                name="UserName"
                rules={[
                  { required: true, message: "Please enter your username" },
                  {
                    min: 2,
                    max: 100,
                    message:
                      "user name must be at least 2 and 100 characters long",
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="User Name" />
              </Item>
              <Item
                name="FullName"
                rules={[{ required: true, message: "Please enter your Name" }]}
              >
                <Input prefix={<UserOutlined />} placeholder="Name" />
              </Item>
              <Item
                name="CNIC"
                rules={[
                  { required: true, message: "Please enter your CNIC " },
                  {
                    pattern: /^(?=.*[0-9])\w{13,}$/, // Regular expression for numeric value and minimum length of 13
                    message: "CNIC must contain 13 characters",
                  },
                ]}
              >
                <Input prefix={<NumberOutlined />} placeholder="CNIC" />
              </Item>
              <Item
                name="ContactNo"
                rules={[
                  { required: true, message: "Please enter your Contact No" },
                  {
                    pattern: /^(?=.*[0-9])\w{11,}$/, // Regular expression for numeric value and minimum length of 13
                    message: "Contact No must contain 11 characters",
                  },
                ]}
              >
                <Input prefix={<PhoneOutlined />} placeholder="Contact No" />
              </Item>
              <Item
                name="Password"
                rules={[
                  { required: true, message: "Please enter your password" },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Item>
              <Item
                name="Password_confirmation"
                rules={[
                  {
                    required: true,
                    message: "Please enter your confirm password",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Confirm Password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Item>
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}
              <Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={processing}
                >
                  {processing ? "Processing..." : "Register"}
                </Button>
              </Item>
            </Form>
            <p>
              <Link to="/login" className="text-center">
                Login to Register!
              </Link>
            </p>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Register;
