// Logout.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear all localStorage items
    localStorage.clear();
    // Redirect to the login page
    navigate("/login");
  }, [navigate]);

  return null; // This component doesn't render anything
}

export default Logout;
