import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Card, Col, Row, DatePicker } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  createVoucher,
  fetchAccounts,
} from "../services/ApiServices/apiFunctions";
import SearchableSelect from "../components/common/SearchableSelect";

interface Account {
  id: number;
  account_name: string;
  parent_id: number;
}

interface VoucherFormValues {
  pay_mode: string;
  voucher_type: number;
  cheque_no: string;
  voucher_date: Date;
  description: string;
  amount: number;
  account_id: number;
}

function OpeningForm() {
  const [form] = Form.useForm();
  const [adding, setAdding] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleAddVoucher = async (values: VoucherFormValues) => {
    setAdding(true);
    try {
      values.voucher_date = dayjs(values.voucher_date, "YYYY-MM-DD").toDate();
      values.pay_mode = "CREDIT";
      values.voucher_type = 3;
      const response = await createVoucher(values);

      if (response.responseCode === 0) {
        message.success(response.responseDescription);
        form.resetFields();
        setSelectedDate(dayjs(values.voucher_date));
      } else {
        message.error("Failed to add opening balance");
      }
    } catch (error) {
      message.error("Failed to add opening balance");
    } finally {
      setAdding(false);
    }
  };

  const handleAccountChange = (value: number) => {
    form.setFieldsValue({ account_id: value });
  };

  // Fetch accounts when component mounts
  useEffect(() => {
    const fetchAccountsData = async (): Promise<void> => {
      try {
        const accountsData: Account[] = await fetchAccounts();
        const filteredAccounts = accountsData.filter((account) =>
          [1, 2, 3, 4].includes(account.parent_id)
        );
        setAccounts(filteredAccounts);
      } catch (error) {
        message.error("Error while fetching accounts");
      }
    };

    form.setFieldsValue({
      voucher_date: selectedDate,
      description: "OPENING",
    });
    fetchAccountsData();
  }, [form, selectedDate]);

  return (
    <div>
      <Card title={<span>Opening Form</span>}>
        <Form
          form={form}
          onFinish={handleAddVoucher}
          layout="vertical"
          size="middle"
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="voucher_date"
                label="Date"
                rules={[{ required: true, message: "Date is required" }]}
              >
                <DatePicker
                  defaultValue={
                    form.getFieldValue("voucher_date")
                      ? dayjs(form.getFieldValue("voucher_date"))
                      : selectedDate
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: "Description is required" }]}
              >
                <Input
                  placeholder="Enter description"
                  defaultValue={"OPENING"}
                  readOnly
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="account_id"
                label="Select Account"
                rules={[{ required: true, message: "Account is required" }]}
              >
                <SearchableSelect
                  options={accounts}
                  placeholder="Select an Account"
                  onChange={handleAccountChange}
                  idKey="id"
                  nameKey="account_name"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[{ required: true, message: "Amount is required" }]}
              >
                <Input
                  type="number"
                  placeholder="Enter amount"
                  style={{ textAlign: "right" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={adding}
              disabled={adding}
              icon={<SaveOutlined />}
            >
              Add Opening
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default OpeningForm;
